/**
 * During refactor of NPR feature out of the render context
 * Created the RenderContextExtension as base interface class for extension developers to consume
 * 
 * And we will integrate the RenderContextExtension into main RenderContext as a valid extension 
 * to allow other developers to implement their own post process rendering effect
 * 
 */
class RenderContextPostProcessExtension {
    constructor(renderContext) {
        this.renderContext = renderContext;
    }

    /**
     * lifecycle function
     */
    load() {
    }

    /**
     * lifecycle function
     */
    unload() {
        this.renderContext = undefined;
    }

    /**
     * Render function
     * @param {WebGLRender} renderer 
     * @param {WebGLRenderTarget} outTarget 
     * @param {WebGLRenderTarget} inTarget 
     */
    render(renderer, outTarget, inTarget) {

    }

    /**
     * Change shader uniform value
     * @param {string} key 
     * @param {any} value 
     * @param {boolean} usingCopy 
     */
    updateUniformValue(key, value, usingCopy) {

    }

    /**
     * Update RenderPass resolutions
     * @param {int} resX 
     * @param {int} resY 
     */
    changeResolution(resX, resY) {

    }

    /**
     * Call when we need to update the material
     */
    setMaterialNeedsUpdate() {

    }

    /**
     * return true if the edges on
     * @returns boolean
     */
    postProcessEdgesOn() {

    }

    /**
     * UI Extension class definition for RenderOptionsPanel.js
     * 
     * @returns {RenderOption} class
     */
    getRenderOptionClass() {

    }
}

/**
 * This class is used to work as an RenderContext Post Processing Shading Manager
 * 
 */
class RenderContextPostProcessManager extends RenderContextPostProcessExtension {

    /**
     * Register the real PostProcessExtension instance
     * 
     * @param {RenderContextPostProcessExtension} extension 
     */
    registerPostProcessingExtension(extension) {
        this.extension = extension;
    }

    /**
     * Remove the PostProcessExtension instance
     */
    removePostProcessingExtension() {
        if (this.extension) {
            this.extension.unload();
            this.extension = undefined;
        }
    };

    getRenderOptionClass() {
        if (this.extension) {
            return this.extension.getRenderOptionClass();
        }
    }

    preloadPostProcessStyle(style) {
        if (this.extension) {
            this.extension.preloadPostProcessStyle(style);
        }
    }

    setPostProcessParameter(token, value) {
        if (this.extension) {
            this.extension.setPostProcessParameter(token, value);
        }
    }

    render(renderer, outTarget, inTarget) {
        if (this.extension) {
            this.extension.render(renderer, outTarget, inTarget);
        }
    }

    updateUniformValue(key, value, usingCopy) {
        if (this.extension) {
            this.extension.updateUniformValue(key, value, usingCopy);
        }
    }

    changeResolution(resX, resY) {
        if (this.extension) {
            this.extension.changeResolution(resX, resY);
        }
    }

    setMaterialNeedsUpdate() {
        if (this.extension) {
            this.extension.setMaterialNeedsUpdate();
        }
    }

    postProcessEdgesOn() {
        if (this.extension) {
            return this.extension.postProcessEdgesOn();
        }
    }

}

export { RenderContextPostProcessExtension, RenderContextPostProcessManager };