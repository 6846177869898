// Helper class to generate MSDF, copied from https://github.com/Chlumsky/msdfgen
// AUTHOR: Virgil Dong, Eric Haines
//**************************************************************************/

import * as THREE from "three";

const SIGNED_DISTANCE_INF = -1e240;

function SignedDistance( ) {
    this.distance = SIGNED_DISTANCE_INF;
    this.dot = 1;
}

SignedDistance.prototype = {

    constructor: SignedDistance,

    set: function ( distance, dot ) {
        this.distance = distance;
        this.dot = dot;

        return this;
    },

    copy: function ( sd ) {
        this.distance = sd.distance;
        this.dot = sd.dot;
    },

    lessThan: function ( sd ) {
        return Math.abs(this.distance) < Math.abs(sd.distance) || (Math.abs(this.distance) === Math.abs(sd.distance) && this.dot < sd.dot);
    },

    greaterThan: function ( sd ) {
        return Math.abs(this.distance) > Math.abs(sd.distance) || (Math.abs(this.distance) === Math.abs(sd.distance) && this.dot > sd.dot);
    },

    lessThanOrEquals: function ( sd ) {
        return Math.abs(this.distance) < Math.abs(sd.distance) || (Math.abs(this.distance) == Math.abs(sd.distance) && this.dot <= sd.dot);
    },

    greaterThanOrEquals: function ( sd ) {
        return Math.abs(this.distance) > Math.abs(sd.distance) || (Math.abs(this.distance) == Math.abs(sd.distance) && this.dot >= sd.dot);
    },

};

function cross2d( a, b ) {
    return (a.x*b.y - a.y*b.x);
}

function getOrthonormal( p, polarity, allowZero ) {
    polarity = polarity;    // true if not specified
    allowZero = allowZero;  // false if not specified
    let len = p.length();
    if (len === 0)
        return polarity ? new THREE.Vector2(0.0, allowZero ? 0.0 : 1.0) : new THREE.Vector2(0.0, -(allowZero ? 0.0 : 1.0));
    return polarity ? new THREE.Vector2(-p.y / len, p.x / len) : new THREE.Vector2(p.y / len, -p.x / len);
}

export const MSDF_EDGE_COLOR_BLACK = 0;
export const MSDF_EDGE_COLOR_RED = 1;
export const MSDF_EDGE_COLOR_GREEN = 2;
export const MSDF_EDGE_COLOR_YELLOW = 3;
export const MSDF_EDGE_COLOR_BLUE = 4;
export const MSDF_EDGE_COLOR_MAGENTA = 5;
export const MSDF_EDGE_COLOR_CYAN = 6;
export const MSDF_EDGE_COLOR_WHITE = 7;


function MSDFLinearSegment( pt1, pt2, color ) {

    this.p = [];
    this.p[0] = pt1.clone();
    this.p[1] = pt2.clone();
    this.color = color;

}

MSDFLinearSegment.prototype = {

    constructor: MSDFLinearSegment,

    set: function ( color ) {
        this.color = color;

        return this;
    },

	clone: function () {

        return new this.constructor( this.p[0], this.p[1], this.color );

    },

    /// Returns the point on the edge specified by the parameter (between 0 and 1).
    point: function ( param ) {
        return this.p[0].clone().lerp( this.p[1], param );
    },

    /// Returns the direction the edge.
    direction: function ( ) {
        return this.p[1].clone().sub( this.p[0] );
    },

    /// Returns the minimum signed distance between origin and the edge.
    signedDistance: function ( origin ) {
        let aq = origin.clone().sub( this.p[0] );
        let ab = this.direction();
        let param = aq.dot( ab ) / ab.dot( ab );
        let eq = this.p[(param > .5)? 1 : 0].clone().sub( origin );
        let endpointDistance = eq.length();
        if (param > 0 && param < 1) {
            let orthoDistance = getOrthonormal(ab,false,false).dot(aq);
            if (Math.abs(orthoDistance) < endpointDistance) {
                let sd = new SignedDistance();
                sd.set( orthoDistance, 0);
                return [sd, param];
            }
        }
        /// Returns 1 for non-negative values and -1 for negative values.
        let nzs = 2 * (cross2d(aq,ab) > 0 ? 1 : 0 ) - 1;
        let sd = new SignedDistance();
        sd.set(nzs*endpointDistance, Math.abs(ab.normalize().dot(eq.normalize(eq))));
        return [sd, param];
    },


    /// Converts a previously retrieved signed distance from origin to pseudo-distance.
    distanceToPseudoDistance: function ( sd, origin, param) {
        let pseudoDistance, dir, ts;
        if (param < 0) {
            dir = this.direction().normalize();
            let aq = origin.clone().sub( this.p[0] );
            ts = aq.dot( dir );
            if (ts < 0) {
                pseudoDistance = cross2d(aq, dir);
                if (Math.abs(pseudoDistance) <= Math.abs(sd.distance)) {
                    sd.distance = pseudoDistance;
                    sd.dot = 0;
                }
            }
        }
        else if (param > 1) {
            // note that this line is the same as above. In the original it is direction(0) vs. direction(1),
            // but for LinearSegment::direction the parameter 0 or 1 is ignored
            dir = this.direction().normalize();
            let bq = origin.clone().sub( this.p[1] );
            ts = bq.dot(dir);
            if (ts > 0) {
                pseudoDistance = cross2d(bq, dir);
                if (Math.abs(pseudoDistance) <= Math.abs(sd.distance)) {
                    sd.distance = pseudoDistance;
                    sd.dot = 0;
                }
            }
        }
    },

    /// Moves the start point of the edge segment.
    moveStartPoint: function ( to ) {
        this.p[0].copy( to );
    },

    /// Moves the end point of the edge segment.
    moveEndPoint: function ( to ) {
        this.p[1].copy( to );
    },

    /// Splits the edge segments into thirds which together represent the original edge.
    splitInThirds: function ( part1, part2, part3 ) {
        // this could be made more efficient by saving the interpoa
        let oneThird = this.point(1 / 3.);
        let twoThirds = this.point(2 / 3.);
        part1 = new MSDFLinearSegment(this.p[0], oneThird, this.color);
        part2 = new MSDFLinearSegment(oneThird, twoThirds, this.color);
        part3 = new MSDFLinearSegment(twoThirds, this.p[1], this.color);
    },


};


function shoelace( a, b ) {
    return (b.x - a.x)*(a.y + b.y);
}

function sign( n ) {
    return ( n > 0 ? 1 : (n < 0 ? -1 : 0));
}


function MSDFContour( ) {

    this.edges = [];

}

MSDFContour.prototype = {

    constructor: MSDFContour,

    addEdge: function ( lineSeg ) {

        this.edges.push( lineSeg );
    },

    /// Computes the bounding box of the contour.
    // never called, so not translated: void bounds(double &l, double &b, double &r, double &t) const;

    /// Computes the winding of the contour. Returns 1 if positive, -1 if negative, 0 if no edges
    winding: function ( ) {

        if (this.edges.length === 0)
            return 0;

        let total = 0;
        let a,b,c,d;
        if (this.edges.length == 1) {
            a = new THREE.Vector2();
            b = new THREE.Vector2();
            c = new THREE.Vector2();
            a.copy( this.edges[0].point(0) );
            b.copy( this.edges[0].point(1 / 3.) );
            c.copy( this.edges[0].point(2 / 3.) );
            total += shoelace(a, b);
            total += shoelace(b, c);
            total += shoelace(c, a);
        }
        else if (this.edges.length == 2) {
            a = new THREE.Vector2();
            b = new THREE.Vector2();
            c = new THREE.Vector2();
            d = new THREE.Vector2();
            a.copy( this.edges[0].point(0) );
            b.copy( this.edges[0].point(.5) );
            c.copy( this.edges[1].point(0) );
            d.copy( this.edges[1].point(.5) );
            total += shoelace(a, b);
            total += shoelace(b, c);
            total += shoelace(c, d);
            total += shoelace(d, a);
        }
        else {
            let prev = new THREE.Vector2();
            let cur = new THREE.Vector2();
            prev = this.edges[this.edges.length - 1].point(0);
            for (let ie = 0; ie < this.edges.length; ie++) {
                cur = this.edges[ie].point(0);
                total += shoelace(prev, cur);
                prev = cur;
            }
        }
        return sign(total);
    },

};

// Is the shared vertex a corner? Inputs must be normalized.
function isCorner( aDir,  bDir, crossThreshold) {
    // more than 90 degrees, or more than the cross threshold, in terms of the sign of the angle
    return aDir.dot(bDir) <= 0 || Math.abs(cross2d(aDir, bDir)) > crossThreshold;
}

function switchColor( color, seed) {
    return switchColorBanned( color, seed, MSDF_EDGE_COLOR_BLACK );
}
function switchColorBanned( color, seed, banned ) {
    let combined = (color&banned);
    if (combined == MSDF_EDGE_COLOR_RED || combined == MSDF_EDGE_COLOR_GREEN || combined == MSDF_EDGE_COLOR_BLUE) {
        color = (combined^MSDF_EDGE_COLOR_WHITE);
        return [color,seed];
    }
    if (color == MSDF_EDGE_COLOR_BLACK || color == MSDF_EDGE_COLOR_WHITE) {
        let start = [ MSDF_EDGE_COLOR_CYAN, MSDF_EDGE_COLOR_MAGENTA, MSDF_EDGE_COLOR_YELLOW ];
        color = start[seed % 3];
        seed = Math.floor(seed/3);
        return [color,seed];
    }
    let shifted = color << (1 + (seed & 1));
    color = (shifted | shifted >> 3) & MSDF_EDGE_COLOR_WHITE;
    seed >>= 1;
    return [color,seed];
}

function EdgePoint( ) {

    this.minDistance = new SignedDistance();
    this.nearEdge = null;
    this.nearParam = 0;

}

EdgePoint.prototype = {

    constructor: EdgePoint,

    clear: function ( ) {
        // note that the SignedDistance is not cleared
        this.nearEdge = null;
        this.nearParam = 0;
    },

    copy: function ( ep ) {
        this.minDistance.copy( ep.minDistance );
        this.nearEdge = ep.nearEdge;
        this.nearParam = ep.nearParam;
    }
};

function median( a, b, c ) {
    return Math.max(Math.min(a, b), Math.min(Math.max(a, b), c));
}

function pointToEdgeDistance( origin, edge ) {
    let a = edge.point(0);
    let b = edge.point(1);
    let q = origin;
    let aq = origin.clone().sub( a );
    let ab = b.clone().sub( a );
    let param = aq.dot(ab) / ab.dot(ab);

    if (param < 0)
    {
        return aq.length();
    }
    else if (param > 1)
    {
        return q.clone().sub(b).length();
    }
    else
    {
        return Math.abs(getOrthonormal(ab, false, false).dot(aq));
    }
}


function MSDFShape( ) {

    this.contours = [];
    this.windings = [];
    this.inverseYAxis = false;  // TODOTODO might need to set to true for WebGL; right now MSDF itself ignores this value entirely, so an implementation of reversal code may be needed

}

//Object.defineProperties( MSDF.prototype, {} );

MSDFShape.prototype = {

    constructor: MSDFShape,

    /// Adds a contour.
    addContour: function ( contour ) {
        this.contours.push( contour );
    },

    /// Adds a blank contour and returns its reference.
    addBlankContour: function ( ) {
        // push and return
        let contour = new MSDFContour();
        this.contours.push( contour );
        return contour;
    },

    // once contours are defined, copy over windings from all contours
    initialize: function ( ) {
        let contourCount = this.contours.length;
        for (let ic = 0; ic < this.contours.length; ++ic) {
            let contour = this.contours[ic];
            this.windings.push(contour.winding());
        }
    },

    /// Performs basic checks to determine if the object represents a valid shape.
    // not translated, as never called: bool validate() const;
    /// Computes the shape's bounding box.
    // not translated, as never called: void bounds(double &l, double &b, double &r, double &t) const;

    /// coloring edges
    edgeColoringSimple: function( angleThreshold, seed) {
        let crossThreshold = Math.sin(angleThreshold);
        for (let ic = 0; ic < this.contours.length; ++ic) {
            // Identify corners
            let contour = this.contours[ic];
            let corners = [];
            if (contour.edges.length > 0) {
                let prevDirection = contour.edges[contour.edges.length-1].direction(1);
                for (let ie = 0; ie < contour.edges.length; ++ie) {
                    let edge = contour.edges[ie];
                    if (isCorner(prevDirection.normalize(), edge.direction(0).normalize(), crossThreshold))
                        corners.push(ie);
                    prevDirection = edge.direction(1);
                }
            }

            // Smooth contour
            if (corners.length === 0) {
                for (let ie = 0; ie < contour.edges.length; ++ie) {
                    contour.edges[ie].color = MSDF_EDGE_COLOR_WHITE;
                }
            // "Teardrop" case
            }
            else if (corners.length == 1) {
                let colors = [ MSDF_EDGE_COLOR_WHITE, MSDF_EDGE_COLOR_WHITE ];
                let results = switchColor(colors[0], seed);
                colors[0] = results[0]; seed = results[1];
                colors[2] = colors[0];
                results = switchColor(colors[0], seed);
                colors[0] = results[0]; seed = results[1];
                let corner = corners[0];
                if (contour.edges.length >= 3) {
                    let m = contour.edges.length;
                    // this equation basically gives 0,1,2 for indices, spread fairly equally across the range.
                    for (let i = 0; i < m; ++i) {
                        // original was:
                        // (colors + 1)[int(3 + 2.875*i / (m - 1) - 1.4375 + .5) - 3];
                        // This uses a trick that when i > -1.0 it will round to 0. The intent is to give
                        // for, say, m=6 a range 1,1,1,1,2,2
                        // Simplification:
                        // colors[int(4 + 2.875*i / (m - 1) - 1.4375 + .5) - 3];
                        // colors[int(1 + 2.875*i / (m - 1) - 0.9375)];
                        // colors[int(2.875*i / (m - 1) + 0.0625)];
                        // Verified my fix was OK with the author: https://github.com/Chlumsky/msdfgen/issues/62
                        contour.edges[(corner + i) % m].color = colors[Math.floor(2.875*i / (m - 1) + 0.0625)];
                    }
                }
                else if (contour.edges.length >= 1) {
                    // Less than three edge segments for three colors => edges must be split
                    let parts = [];
                    contour.edges[0].splitInThirds(parts[0 + 3 * corner], parts[1 + 3 * corner], parts[2 + 3 * corner]);
                    if (contour.edges.length >= 2) {
                        contour.edges[1].splitInThirds(parts[3 - 3 * corner], parts[4 - 3 * corner], parts[5 - 3 * corner]);
                        parts[0].color = parts[1].color = colors[0];
                        parts[2].color = parts[3].color = colors[1];
                        parts[4].color = parts[5].color = colors[2];
                    }
                    else {
                        parts[0].color = colors[0];
                        parts[1].color = colors[1];
                        parts[2].color = colors[2];
                    }
                    contour.edges.clear();
                    for (let i = 0; i < parts.length; ++i)
                        contour.edges.push(parts[i]);
                }
            }
            // Multiple corners
            else {
                let cornerCount = corners.length;
                let spline = 0;
                let start = corners[0];
                let m = contour.edges.length;
                let color = MSDF_EDGE_COLOR_WHITE;
                let results = switchColor(color, seed);
                color = results[0]; seed = results[1];
                let initialColor = color;
                for (let i = 0; i < m; ++i) {
                    let index = (start + i) % m;
                    if (spline + 1 < cornerCount && corners[spline + 1] == index) {
                        ++spline;
                        results = switchColorBanned(color, seed, ((spline === cornerCount - 1) ? 1 : 0 ) * initialColor );
                        color = results[0]; seed = results[1];
                    }
                    contour.edges[index].color = color;
                }
            }
        }
    },

    /// calculate msdf value for point p
    calculateMSDFValue: function(p) {
        let contourCount = this.contours.length;
        let contourSD = [];

        let sr = new EdgePoint();
        let sg = new EdgePoint();
        let sb = new EdgePoint();
        let d = Math.abs(SIGNED_DISTANCE_INF);
        let negDist = -SIGNED_DISTANCE_INF;
        let posDist = SIGNED_DISTANCE_INF;
        let winding = 0;

        let r = new EdgePoint();
        let g = new EdgePoint();
        let b = new EdgePoint();
        for (let i = 0; i < this.contours.length; ++i) {
            contourSD[i] = {    // MultiDistance
                r: SIGNED_DISTANCE_INF,
                g: SIGNED_DISTANCE_INF,
                b: SIGNED_DISTANCE_INF,
                med: SIGNED_DISTANCE_INF,
            };
            let contour = this.contours[i];
            r.clear();
            g.clear();
            b.clear();

            for (let ie = 0; ie < contour.edges.length; ++ie) {
                let edge = contour.edges[ie];
                let resultArray = edge.signedDistance(p);
                let distance = resultArray[0];
                let param = resultArray[1];
                if (((edge.color&MSDF_EDGE_COLOR_RED)>0) && (distance.lessThan( r.minDistance ))) {
                    r.minDistance.copy( distance );
                    r.nearEdge = edge;
                    r.nearParam = param;
                }
                if (((edge.color&MSDF_EDGE_COLOR_GREEN)>0) && (distance.lessThan( g.minDistance))) {
                    g.minDistance.copy( distance );
                    g.nearEdge = edge;
                    g.nearParam = param;
                }
                if (((edge.color&MSDF_EDGE_COLOR_BLUE)>0) && (distance.lessThan( b.minDistance))) {
                    b.minDistance.copy( distance );
                    b.nearEdge = edge;
                    b.nearParam = param;
                }
            }
            if (r.minDistance.lessThan( sr.minDistance))
                sr.copy( r );
            if (g.minDistance.lessThan( sg.minDistance))
                sg.copy( g );
            if (b.minDistance.lessThan( sb.minDistance))
                sb.copy( b );

            let medMinDistance = Math.abs(median(r.minDistance.distance, g.minDistance.distance, b.minDistance.distance));
            if (medMinDistance < d) {
                d = medMinDistance;
                winding = -this.windings[i];
            }
            if (r.nearEdge)
                r.nearEdge.distanceToPseudoDistance(r.minDistance, p, r.nearParam);
            if (g.nearEdge)
                g.nearEdge.distanceToPseudoDistance(g.minDistance, p, g.nearParam);
            if (b.nearEdge)
                b.nearEdge.distanceToPseudoDistance(b.minDistance, p, b.nearParam);
            medMinDistance = median(r.minDistance.distance, g.minDistance.distance, b.minDistance.distance);
            contourSD[i].r = r.minDistance.distance;
            contourSD[i].g = g.minDistance.distance;
            contourSD[i].b = b.minDistance.distance;
            contourSD[i].med = medMinDistance;
            if (this.windings[i] > 0 && medMinDistance >= 0 && Math.abs(medMinDistance) < Math.abs(posDist))
                posDist = medMinDistance;
            if (this.windings[i] < 0 && medMinDistance <= 0 && Math.abs(medMinDistance) < Math.abs(negDist))
                negDist = medMinDistance;
        }
        if (sr.nearEdge)
            sr.nearEdge.distanceToPseudoDistance(sr.minDistance, p, sr.nearParam);
        if (sg.nearEdge)
            sg.nearEdge.distanceToPseudoDistance(sg.minDistance, p, sg.nearParam);
        if (sb.nearEdge)
            sb.nearEdge.distanceToPseudoDistance(sb.minDistance, p, sb.nearParam);

        // MultiDistance class
        let msd = {
            r: SIGNED_DISTANCE_INF,
            g: SIGNED_DISTANCE_INF,
            b: SIGNED_DISTANCE_INF,
            med: SIGNED_DISTANCE_INF,
        };
        if (posDist >= 0 && Math.abs(posDist) <= Math.abs(negDist)) {
            msd.med = SIGNED_DISTANCE_INF;
            winding = 1;
            for (let i = 0; i < contourCount; ++i)
                if (this.windings[i] > 0 && contourSD[i].med > msd.med && Math.abs(contourSD[i].med) < Math.abs(negDist))
                    msd = contourSD[i];
        }
        else if (negDist <= 0 && Math.abs(negDist) <= Math.abs(posDist)) {
            msd.med = -SIGNED_DISTANCE_INF;
            winding = -1;
            for (let i = 0; i < contourCount; ++i)
                if (this.windings[i] < 0 && contourSD[i].med < msd.med && Math.abs(contourSD[i].med) < Math.abs(posDist))
                    msd = contourSD[i];
        }
        for (let i = 0; i < contourCount; ++i) {
            if (this.windings[i] != winding && Math.abs(contourSD[i].med) < Math.abs(msd.med)) {
                // note that we "cheat" here, vs. the original code, which copies the values. This is not
                // needed as msd gets used at the end, and it and the contourSD is discarded.
                msd = contourSD[i];
            }
        }
        if (median(sr.minDistance.distance, sg.minDistance.distance, sb.minDistance.distance) == msd.med) {
            msd.r = sr.minDistance.distance;
            msd.g = sg.minDistance.distance;
            msd.b = sb.minDistance.distance;
        }

        return new THREE.Vector3(msd.r, msd.g, msd.b);
    },

    /// calculate minium distance between edges that colored same color
    minSameColoredEdgeDistance: function () {
        // all edges are colored by 3 colors in all.
        let minDistance = [ 1e10, 1e10, 1e10 ];
        for (let ic = 0; ic < this.contours.length; ++ic) {
            let contour = this.contours[ic];
            for (let i = 0; i < contour.edges.length; ++i) {
                let edge = contour.edges[i];
                // neighbor edges would not colored by same color, so inner loop ends at i-1
                for (let j = 0; j + 1 < i; ++j)
                {
                    let otherEdge = contour.edges[j];
                    if (edge.color == otherEdge.color)
                    {
                        // as the MSDF algorithm would fail on self-intersected polygon, we only can
                        // simply assume all same colored edges are not intersected here. In this
                        // case, the minimun distance between two edges are the min distance of end
                        // points to the other edge.
                        let dist = Math.min(
                            Math.min(pointToEdgeDistance(edge.point(0), otherEdge),
                                    pointToEdgeDistance(edge.point(1), otherEdge)),
                            Math.min(pointToEdgeDistance(otherEdge.point(0), edge),
                                    pointToEdgeDistance(otherEdge.point(1), edge))
                        );

                        let idx = edge.color - MSDF_EDGE_COLOR_MAGENTA;
                        minDistance[idx] = Math.min(minDistance[idx], dist);
                    }
                }
            }
        }
        return Math.min(Math.min(minDistance[0], minDistance[1]), minDistance[2]);
    }

};

// not translated, as never called: void MsdfErrorCorrection(unsigned char*output, int width, int height, int bytesPerPixel, const OGS::float2 &threshold);

export { MSDFShape, MSDFContour, MSDFLinearSegment };
