
export var SnapType = {
    SNAP_VERTEX: 0,
    SNAP_MIDPOINT: 1,
    SNAP_CIRCLE_CENTER: 2,
    SNAP_EDGE: 3,
    SNAP_FACE: 4,
    SNAP_CIRCULARARC: 5,
    SNAP_CURVEDEDGE: 6,
    SNAP_CURVEDFACE: 7
};
