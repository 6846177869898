
import { SceneMath } from "../wgs/scene/SceneMath";

/**
 * Used in order to convert from Leaflet coordinates to PDF coordinates.
 * 
 * Assumes the current model is loaded with PDFLoader.
 */
function leafletToPdfWorld(viewer, point) {
    const leafletNormalizingMatrix = getLeafletNormalizingMatrix(viewer);
    point.applyMatrix4(leafletNormalizingMatrix);

    let pdfNormalizingMatrix = SceneMath.getNormalizingMatrix(viewer.model);
    pdfNormalizingMatrix = pdfNormalizingMatrix.getInverse(pdfNormalizingMatrix);
    point.applyMatrix4(pdfNormalizingMatrix);

    return point;
};

/**
 * Used in order to convert from PDF coordinates to Leaflet coordinates.
 * 
 * Assumes the current model is loaded with PDFLoader.
 */
function pdfToLeafletWorld(viewer, point) {
    const pdfNormalizingMatrix = SceneMath.getNormalizingMatrix(viewer.model);
    point.applyMatrix4(pdfNormalizingMatrix);

    let leafletNormalizingMatrix = getLeafletNormalizingMatrix(viewer);
    leafletNormalizingMatrix = leafletNormalizingMatrix.getInverse(leafletNormalizingMatrix);
    point.applyMatrix4(leafletNormalizingMatrix);

    return point;
};

/**
 * Searches inside the bubble for the Leaflets params.
 */
function getLeafletLoadOptions(viewer) {
  const documentNode = viewer.impl.model.getDocumentNode();
  const leafletItem = documentNode.search(Autodesk.Viewing.BubbleNode.LEAFLET_NODE)[0]._raw();

  const options = {};
  const document = new Autodesk.Viewing.Document(documentNode.getRootNode()._raw(), '');
  document.getLeafletParams(options, documentNode, leafletItem);

  return options;
};

/**
 * Calculates the Leaflet's bounding box, using parameters from the bubble.
 * Use the same original logic from the leaflet loader.
 */
function getLeafletBoundingBox(viewer) {
  const texQuadConfig = new Autodesk.Viewing.Private.TexQuadConfig();
  const options = getLeafletLoadOptions(viewer);
  texQuadConfig.initFromLoadOptions(null, options);
  const boundingBox = texQuadConfig.getBBox();

  return boundingBox;
};

function getLeafletNormalizingMatrix(viewer) {
  const bbox = getLeafletBoundingBox(viewer);
  const matrix = SceneMath.getNormalizingMatrix(null, bbox);

  return matrix;
};


export var PDFUtils = {
    leafletToPdfWorld,
    pdfToLeafletWorld,
    getLeafletLoadOptions,
    getLeafletBoundingBox,
    getLeafletNormalizingMatrix
};
