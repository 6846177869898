import * as THREE from "three";

// Simple helper to describe uv offset and scale
export function UVTransform() {
    this.offsetX = 0.0;
    this.offsetY = 0.0;
    this.scaleX  = 1.0;
    this.scaleY  = 1.0;
}

UVTransform.prototype.toVec4 = function() {
    return new THREE.Vector4(this.offsetX, this.offsetY, this.scaleX, this.scaleY);
};

UVTransform.prototype.copyTo = function(otherUV) {
    otherUV.offsetX = this.offsetX;
    otherUV.offsetY = this.offsetY;
    otherUV.scaleX = this.scaleX;
    otherUV.scaleY = this.scaleY;
};

export function GeometryManager() {
    // reused geometry for on-the-fly generated fallback tiles, which require individual uv-coords
    // It would be better to share _quadGeom for these as well. But this is would require a solution
    // first how we can use the same texture with different uvTransforms in a single frame.
    var _reusedGeoms = [];  // {THREE.Geometry}
    // index to the first elem in _reusedGeoms that has not been used for the current frame yet.
    var _nextFreeGeom = 0;

    const _uvTransformIdentity = new UVTransform();

    /** Updates the uv-coords for a given quad geometry.
     *   @param {THREE.Geometry} geom
     *   @param {UVTransform}    [uvTransform] - default: identity
     */
    function setUVCoords(geom, uvTransform) {

        var tf = (uvTransform ? uvTransform : _uvTransformIdentity);

        var uvs = [];
        uvs.push( new THREE.Vector2(tf.offsetX            , tf.offsetY));
        uvs.push( new THREE.Vector2(tf.offsetX + tf.scaleX, tf.offsetY));
        uvs.push( new THREE.Vector2(tf.offsetX + tf.scaleX, tf.offsetY + tf.scaleY));
        uvs.push( new THREE.Vector2(tf.offsetX            , tf.offsetY + tf.scaleY));

        geom.faceVertexUvs[0].length = 0;
        geom.faceVertexUvs[0].push([uvs[0], uvs[1], uvs[2]]);
        geom.faceVertexUvs[0].push([uvs[0], uvs[2], uvs[3]]);
        geom.uvTransform = tf;
        geom.uvsNeedUpdate = true;
    }

    /**
     * Returns a reusable geometry and recomputes its uv coords based on given scale and offset.
     *  @param   {UVTransform}    [uvOffsetX]
     *  @returns {THREE.Geometry} A geometry from _reusedGeoms
     */
    this.acquireQuadGeom = function(uvTransform) {

        // get next reusable mesh and increase counter
        var geom = _reusedGeoms[_nextFreeGeom];

        // if not available yet, create it
        if (!geom) {
            geom = this.createQuadGeom(uvTransform);

            // keep it for reuse in later frames
            _reusedGeoms[_nextFreeGeom] = geom;
        } else {
            // reuse old geom and just update uv
            setUVCoords(geom, uvTransform);
        }

        // inc counter so that this geom is not used again in this frame
        _nextFreeGeom++;
        return geom;
    };

    /**
     *  @param {UVTransform} [uvTransform]
     *  @returns {THREE.Geometry}
     */
    this.createQuadGeom = function(uvTransform) {

        // vertices
        var geom = new THREE.Geometry();
        geom.vertices.push(
            new THREE.Vector3(0.0, 0.0, 0.0),
            new THREE.Vector3(1.0, 0.0, 0.0),
            new THREE.Vector3(1.0, 1.0, 0.0),
            new THREE.Vector3(0.0, 1.0, 0.0)
        );

        // indices
        geom.faces.push(new THREE.Face3(0, 1, 2));
        geom.faces.push(new THREE.Face3(0, 2, 3));

        setUVCoords(geom, uvTransform);

        geom.computeFaceNormals();

        return geom;
    };

    this.reset = function() {
        _nextFreeGeom = 0;
    };

    this.dispose = function() {
        for (var i = 0; i < _reusedGeoms.length; i++) {
            var geom = _reusedGeoms[i];
            if (geom) {
                geom.dispose();
                geom.needsUpdate = true;
            }
        }
    }
}



