
import { logger } from "../logger/Logger";
import { loadDependency } from "../globals";
import * as et from "./EventTypes";


/**
 * The ExtensionManager manages all the extensions available to the viewer.
 * Register, retrieve, and unregister your extension using the singleton `Autodesk.Viewing.theExtensionManager`.
 *
 * You can load/unload your registered extension into a Viewer by invoking 
 * {@link #loadExtension|viewer.loadExtension(id, options)} and
 * {@link #unloadExtension|viewer.unloadExtension(id)}, respectively.
 * 
 * @memberof Autodesk.Viewing
 * @alias Autodesk.Viewing.ExtensionManager
 * @constructor
 */
var ExtensionManager = function () {
    var extensions = {};        // Registered extenesions available in-memory
    var extensionsAsync = {};   // Extensions that need to get downloaded
    var _downloading = [];      // ids for extensions that loadDependency is currently working on.

    /**
     * Registers a new extension with the given id.
     *
     * @param {string} extensionId - The string id of the extension.
     * @param {Extension} extension - The Extension-derived class representing the extension.
     * @returns {boolean} - True if the extension was successfully registered.
     * @alias Autodesk.Viewing.ExtensionManager#registerExtension
     */
    function registerExtension(extensionId, extension) {
        if (extensions[extensionId]) {
            return false;
        }
        extensions[extensionId] = extension;
        return true;
    }

    /**
     * Returns the class representing the extension with the given id.
     *
     * @param {string} extensionId - The string id of the extension.
     * @returns {Extension|null} - The Extension-derived class if one was registered; null otherwise.
     * @alias Autodesk.Viewing.ExtensionManager#getExtension
     */
    function getExtension(extensionId) {
        if (extensions.hasOwnProperty(extensionId)) {
            return extensions[extensionId];
        }
        return null;
    }

    /**
     * Unregisters an existing extension with the given id.
     *
     * @param {string} extensionId - The string id of the extension.
     * @returns {boolean} - True if the extension was successfully unregistered.
     * @alias Autodesk.Viewing.ExtensionManager#unregisterExtension
     */
    function unregisterExtension(extensionId) {
        if (extensions.hasOwnProperty(extensionId)) {
            delete extensions[extensionId];
            return true;
        }
        return false;
    }

    /**
     * Registers an extension that needs to be downloaded before using it.
     * The Viewer ships with some extensions that are not bundled, but can be runtime-fetched.
     *
     * @param {string} extensionId - The string id of the extension.
     * @param {string} urlPath - The url from where it needs to be pulled from. Can be a relative or an absolute path.
     * @returns {boolean} - True if the extension was successfully registered.
     * @alias Autodesk.Viewing.ExtensionManager#registerExternalExtension
     */
    function registerExternalExtension(extensionId, urlPath) {
        if (extensionsAsync[extensionId]) {
            return false;
        }
        extensionsAsync[extensionId] = urlPath;
        return true;
    }

    /**
     * Returns the url path from where to download the extension; null if not registered through registerExternalExtension().
     *
     * @param {string} extensionId - The string id of the extension.
     * @returns {url|null} - The url from where to download the extension; null if not download is needed.
     * @alias Autodesk.Viewing.ExtensionManager#getExternalPath
     */
    function getExternalPath(extensionId) {
        if (extensionsAsync.hasOwnProperty(extensionId)) {
            return extensionsAsync[extensionId];
        }
        return null;
    }

    function unregisterExternalExtension(extensionId) {
        if (extensionsAsync.hasOwnProperty(extensionId)) {
            delete extensionsAsync[extensionId];
            return true;
        }
        return false;
    }

    /**
     * Gets a list of all the extensions that are available for usage.
     * Some are already available in memory, while others may require
     * an additional file to be downloaded prior to its usage.
     * @returns {string[]}
     * @alias Autodesk.Viewing.ExtensionManager#getRegisteredExtensions
     */
    function getRegisteredExtensions() {
        var extensionId;
        var ret = [];
        // in-memory extensions (might have been downloaded)
        for (extensionId in extensions) {
            if (extensions.hasOwnProperty(extensionId)) {
                ret.push({
                    id: extensionId,
                    inMemory: true,
                    isAsync: (extensionId in extensionsAsync)
                });
            }
        }
        // Async extensions (some may already be in memory)
        for (extensionId in extensionsAsync) {
            if (extensionsAsync.hasOwnProperty(extensionId) && !(extensionId in extensions)) {
                ret.push({
                    id: extensionId,
                    inMemory: false,
                    isAsync: true
                });
            }
        }
        return ret;
    }

    /**
     * Iterates over each registered Extension class and invokes
     * static method 'populateDefaultOptions' if available.
     * 
     * The objective is to gather all supported configuration options
     * across all extensions.
     * @private
     */
    function popuplateOptions(options) {
        for (var ext in extensions) {
            if (extensions.hasOwnProperty(ext) && extensions[ext].hasOwnProperty('populateDefaultOptions')){
                extensions[ext].populateDefaultOptions(options);
            }
        }
    }

    /**
     * Checks whether an extension's JavaScript bundle is currently being downloaded.
     * Applicable only to external extensions.
     * @param {string} extensionId - The extension's id
     * @returns {boolean} true is the extension's JavaScript bundle is currently being downloaded. 
     * @private
     */
    function isDownloading(extensionId) {
        return _downloading.indexOf(extensionId) !== -1;
    }

    /**
     * Flags an extension id to indicate whether it is being downloaded or not.
     * Applicable only to external extensions.
     * @param {string} extensionId - The extension's id
     * @param {bool} isDownloading - Set whether it's downloading (true) or not (false)
     * @returns {boolean} true is the the flag was toggled successfully.
     * @private
     */
    function setIsDownloading(extensionId, isDownloading) {
        var index = _downloading.indexOf(extensionId);
        if (isDownloading && index === -1) {
            _downloading.push(extensionId);
            return true
        } else if (!isDownloading && index !== -1) {
            _downloading.splice(index, 1);
            return true;
        }
        return false;
    }

    return {
        registerExtension: registerExtension,
        getExtension: getExtension,
        unregisterExtension: unregisterExtension,
        registerExternalExtension: registerExternalExtension,
        getExternalPath: getExternalPath,
        unregisterExternalExtension: unregisterExternalExtension,
        getRegisteredExtensions: getRegisteredExtensions,
        popuplateOptions: popuplateOptions,
        isDownloading: isDownloading,
        setIsDownloading: setIsDownloading,
    };
};

export var theExtensionManager =  new ExtensionManager();

/***
 * Augments a class by extension load/unload functionality.
 */
export var ExtensionMixin = function() {};

ExtensionMixin.prototype = {

    /**
     * Loads the extension with the given id and options.
     *
     * @memberof! Autodesk.Viewing.Viewer3D#
     * @param {string} extensionId - The string id of the extension.
     * @param {Object} options - An optional dictionary of options.
     *
     * @returns {Promise} - Resolves with the extension requested.
     */
    loadExtension : function (extensionId, options) {

        if (!this.loadedExtensions)
            this.loadedExtensions = {};

        // is it already loaded?
        var extension = this.getExtension(extensionId);
        if (extension) {
            return Promise.resolve(extension);
        }

        // Is the extension registered?
        var EXTENSION_CLASS = theExtensionManager.getExtension(extensionId);
        if (!EXTENSION_CLASS) {

            // Is it an extension that needs to be downloaded?
            var urlPath = theExtensionManager.getExternalPath(extensionId);
            if (urlPath) {
                return this.loadExtensionAsync(extensionId, urlPath, options);
            }

            // else...
            return Promise.reject('Extension not found: ' + extensionId + '. Has it been registered(1)?');
        }

        // Extension has been registered locally.
        return this.loadExtensionLocal(extensionId, options);
    },

    /**
     * Returns the loaded extension.
     * @memberof! Autodesk.Viewing.Viewer3D#
     * @param {string} extensionId - The string id of the extension.
     * @param {function} [callback] - That receives an extension instance as argument.
     * @returns {?Object} - Extension.
     */
    getExtension : function (extensionId, callback) {
        var ext = (this.loadedExtensions && extensionId in this.loadedExtensions) ? this.loadedExtensions[extensionId] : null;
        if (ext && callback) {
            callback(ext);
        }
        return ext;
    },

    /**
     * Unloads the extension with the given id.
     *
     * @memberof! Autodesk.Viewing.Viewer3D#
     * @param {string} extensionId - The string id of the extension.
     * @returns {boolean} - True if the extension was successfully unloaded.
     */
    unloadExtension : function (extensionId) {

        // Cancel async extension load
        theExtensionManager.setIsDownloading(extensionId, false);

        var success = false;
        var ext = this.getExtension(extensionId);
        if (ext) {
            success = ext.unload();
            logger.info('Extension unloaded: ' + extensionId);
            delete this.loadedExtensions[extensionId];
            this.dispatchEvent({ type: et.EXTENSION_UNLOADED_EVENT, extensionId: extensionId });
        } else {
            logger.warn('Extension not found: ' + extensionId);
        }
        return success;
    },


    /**
     * Loads the extension with the given id and options.
     * For internal use only.
     *
     * @memberof! Autodesk.Viewing.Viewer3D#
     * @param {string} extensionId - The string id of the extension.
     * @param {Object} options - An optional dictionary of options.
     *
     * @returns {Promise} - Resolves with the extension requested.
     */
    loadExtensionLocal : function (extensionId, options) {

        var EXTENSION_CLASS = theExtensionManager.getExtension(extensionId);
        if (!EXTENSION_CLASS) {
            return Promise.reject('Extension not found: ' + extensionId + '. Has it been registered(2)?');
        }

        const loadedExtension = this.getExtension(extensionId);

        if (loadedExtension) {
            return Promise.resolve(loadedExtension);
        }

        var extension = new EXTENSION_CLASS(this, options);
        extension.id = extensionId;
        var success = extension.load();
        if (success) {
            this.loadedExtensions[extensionId] = extension;
            this.onPostExtensionLoad(extension);
            logger.info('Extension loaded: ' + extensionId);
            this.dispatchEvent({ type: et.EXTENSION_LOADED_EVENT, extensionId: extensionId });
            // Event might trigger an unload of the extension, so check again...
            var extAgain = this.getExtension(extensionId);
            return extAgain ? Promise.resolve(extAgain) : Promise.reject(`Extension ${extensionId} unloaded by EXTENSION_LOADED_EVENT handler.`);
        }

        // else
        return Promise.reject('Extension failed to .load() : ' + extensionId);
    },

    /**
     * Virtual method that hooks into the extension's loading process.
     * Gets invoked after {@link Autodesk.Viewing.Extension#load|extension.load()} 
     * but before event `EXTENSION_LOADED_EVENT` gets fired.
     *
     * @virtual
     */
    onPostExtensionLoad : function(extension) {
        // virtual method //
    },

    /**
     * Loads an extension JavaScript file from a URL. It will download the file, parse it and
     * then invoke loadExtension().  Calling this function a second time will not download the
     * file again.
     * 
     * @example
     *      viewer.loadExtensionAsync(
     *          'MyExtensionId', 
     *          'http://my.site.com/path/MyExtension.js', 
     *          {}
     *      ).then(function(ext){
     *          ext.doSomething();
     *      }).catch(function(error){
     *          console.error(error);
     *      });
     * 
     * @memberof! Autodesk.Viewing.Viewer3D#
     * @param {string} extensionId - The string id of the extension.
     * @param {string} url - The url where the extension's JavaScript file is hosted. Can be a relative or absolute path.
     * @param {Object} options - An optional dictionary of options, same as in loadExtension().
     * 
     * @returns {promise} - That resolves with the extension object.
     */
    loadExtensionAsync : function(extensionId, url, options) {

        theExtensionManager.setIsDownloading(extensionId, true);

        var that = this;
        return new Promise(function(resolve, reject){
            loadDependency(extensionId, url,
                function() { // onSuccess

                    // abort if the extension has been unloaded while downloading the code bundle
                    if (!theExtensionManager.isDownloading(extensionId) && !that.getExtension(extensionId)) {
                        reject(`Abort loadExtensionAsync('${extensionId}')`);
                        return;
                    }

                    theExtensionManager.setIsDownloading(extensionId, false);
                    that.loadExtensionLocal(extensionId, options)
                        .then(resolve)
                        .catch(reject)
                },
                function () { // onError
                    reject('Failed to loadExtensionAsync: (' + extensionId + ') from: (' + url + ')');
                }
            );
        });
    },

    /**
     * Iterate over each extension that has been successfully loaded and invokes a callback function for them.
     * @param {function} callback - That receives an extension instance as argument.
     *
     * @example
     *    forEachExtension(function(ext){
     *       console.log(ext.id);
     *    })
     *
     * @memberof! Autodesk.Viewing.Viewer3D#
     */
    forEachExtension : function( callback ) {
        var loadedIds = this.loadedExtensions || {};
        for (var id in loadedIds) {
            if (loadedIds.hasOwnProperty(id)) {
                callback(loadedIds[id]);
            }
        }
    },


    apply : function(object) {

        var me = ExtensionMixin.prototype;

        object.loadExtension = me.loadExtension;
        object.getExtension = me.getExtension;
        object.unloadExtension = me.unloadExtension;
        object.loadExtensionLocal = me.loadExtensionLocal;
        object.loadExtensionAsync = me.loadExtensionAsync;
        object.forEachExtension = me.forEachExtension;
        object.onPostExtensionLoad = me.onPostExtensionLoad;

    }

};
