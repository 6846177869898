import * as THREE from "three";
import { ShaderChunks as chunks } from './ShaderChunks';
import { createShaderMaterial } from './ShaderUtils';

import prism_vert from './shaders/prism_vert.glsl';
import prism_frag from './shaders/prism_frag.glsl';

    // This method sets up various uniforms for a given map, putting them
    // in an array called "uniforms" which are accessed by the name, such
    // as "uniforms[surface_albedo_map_texMatrix]".
    export function GetPrismMapUniforms(mapName) {
        var mtxName = mapName + "_texMatrix";
        var mapInvt = mapName + "_invert";

        var uniforms = {};
        uniforms[mapName] = { type: "t", value: null };
        uniforms[mtxName] = { type: "m3", value: new THREE.Matrix3() };
        uniforms[mapInvt] = { type: "i", value: 0 };

        return uniforms;
    }

    function GetPrismBumpMapUniforms(mapName) {
        var mtxName = mapName + "_texMatrix";
        var mapScale = mapName + "_bumpScale";
        var mapType = mapName + "_bumpmapType";

        var uniforms = {};
        uniforms[mapName] = { type: "t", value: null };
        uniforms[mtxName] = { type: "m3", value: new THREE.Matrix3() };
        uniforms[mapScale] = { type: "v2", value: new THREE.Vector2( 1, 1 ) };
        uniforms[mapType] = { type: "i", value: 0 };

        return uniforms;
    }

    export let PrismShader = {

        uniforms: THREE.UniformsUtils.merge( [

            THREE.UniformsLib[ "common" ],
            THREE.UniformsLib[ "lights" ],
            THREE.UniformsLib[ "fog" ],
            chunks.CutPlanesUniforms,
            chunks.IdUniforms,
            chunks.ThemingUniform,
            chunks.ShadowMapUniforms,

            GetPrismMapUniforms("surface_albedo_map"),
            GetPrismMapUniforms("surface_roughness_map"),
            GetPrismMapUniforms("surface_cutout_map"),
            GetPrismMapUniforms("surface_anisotropy_map"),
            GetPrismMapUniforms("surface_rotation_map"),
            GetPrismMapUniforms("opaque_albedo_map"),
            GetPrismMapUniforms("opaque_f0_map"),
            GetPrismMapUniforms("opaque_luminance_modifier_map"),
            GetPrismMapUniforms("layered_bottom_f0_map"),
            GetPrismMapUniforms("layered_f0_map"),
            GetPrismMapUniforms("layered_diffuse_map"),
            GetPrismMapUniforms("layered_fraction_map"),
            GetPrismMapUniforms("layered_roughness_map"),
            GetPrismMapUniforms("layered_anisotropy_map"),
            GetPrismMapUniforms("layered_rotation_map"),
            GetPrismMapUniforms("metal_f0_map"),
            GetPrismMapUniforms("wood_curly_distortion_map"),
            GetPrismMapUniforms("glazing_f0_map"),
            GetPrismMapUniforms("glazing_transmission_color_map"),
            GetPrismMapUniforms("glazing_transmission_roughness_map"),

            GetPrismBumpMapUniforms("surface_normal_map"),
            GetPrismBumpMapUniforms("layered_normal_map"),

            GetPrismMapUniforms("TilingMap"),
            GetPrismMapUniforms("TilingNormalMap"),
            GetPrismMapUniforms("TilingRandomMap"),
            
            {
                //Surface
                "surface_albedo" : { type: "c", value: new THREE.Color( 0x111111 ) },
                "surface_roughness" : { type: "f", value: 1.0 },
                "surface_anisotropy" : { type: "f", value: 1.0 },
                "surface_rotation" : { type: "f", value: 1.0 },

                //Opaque
                "opaque_albedo" : { type: "c", value: new THREE.Color( 0x111111 ) },
                "opaque_f0" : { type: "f", value: 1.0 },
                "opaque_luminance_modifier" : { type: "c", value: new THREE.Color( 0x111111 ) },
                "opaque_luminance" : { type: "f", value: 1.0 },

                //Metal
                "metal_f0" : { type: "c", value: new THREE.Color( 0x111111 ) },

                //Layered
                "layered_f0" : { type: "f", value: 1.0 },
                "layered_diffuse" : { type: "c", value: new THREE.Color( 0x000000 ) },
                "layered_fraction" : { type: "f", value: 1.0 },
                "layered_bottom_f0" : { type: "c", value: new THREE.Color( 0x111111 ) },
                "layered_roughness" : { type: "f", value: 1.0 },
                "layered_anisotropy" : { type: "f", value: 1.0 },
                "layered_rotation" : { type: "f", value: 1.0 },

                //Transparent
                "transparent_ior" : { type: "f", value: 2.0 },
                "transparent_color" : { type: "c", value: new THREE.Color( 0x111111 ) },
                "transparent_distance" : { type: "f", value: 1.0 },

                //Glazing
                "glazing_f0" : { type: "c", value: new THREE.Color( 0xffffff ) },
                "glazing_transmission_roughness" : { type: "f", value: 0.0 },
                "glazing_transmission_color" : { type: "c", value: new THREE.Color( 0xffffff ) },
                
                //Wood
                "wood_fiber_cosine_enable": { type: "i", value: 1 },
                "wood_fiber_cosine_bands": { type: "i", value: 2 },
                "wood_fiber_cosine_weights": { type: "v4", value: new THREE.Vector4(2.5, 0.5, 1, 1) },
                "wood_fiber_cosine_frequencies": { type: "v4", value: new THREE.Vector4(15, 4, 1, 1) },

                "wood_fiber_perlin_enable": { type: "i", value: 1 },
                "wood_fiber_perlin_bands": { type: "i", value: 3 },
                "wood_fiber_perlin_weights": { type: "v4", value: new THREE.Vector4(3.0, 1.0, 0.2, 1) },
                "wood_fiber_perlin_frequencies": { type: "v4", value: new THREE.Vector4(40, 20, 3.5, 1) },
                "wood_fiber_perlin_scale_z": { type: "f", value: 0.3 },

                "wood_growth_perlin_enable": { type: "i", value: 1 },
                "wood_growth_perlin_bands": { type: "i", value: 3 },
                "wood_growth_perlin_weights": { type: "v4", value: new THREE.Vector4(1.0, 2, 1, 1) },
                "wood_growth_perlin_frequencies": { type: "v4", value: new THREE.Vector4(1, 5, 13, 1) },

                "wood_latewood_ratio": { type: "f", value: 0.238 },
                "wood_earlywood_sharpness": { type: "f", value: 0.395 },
                "wood_latewood_sharpness": { type: "f", value: 0.109 },
                "wood_ring_thickness": { type: "f", value: 0.75 },

                "wood_earlycolor_perlin_enable": { type: "i", value: 1 },
                "wood_earlycolor_perlin_bands": { type: "i", value: 2 },
                "wood_earlycolor_perlin_weights": { type: "v4", value: new THREE.Vector4(0.3, 0.5, 0.15, 1) },
                "wood_earlycolor_perlin_frequencies": { type: "v4", value: new THREE.Vector4(8, 3, 0.35, 1) },
                "wood_early_color": { type: "c", value: new THREE.Color(0.286, 0.157, 0.076) },

                "wood_use_manual_late_color": { type: "i", value: 0 },
                "wood_manual_late_color": { type: "c", value: new THREE.Color(0.62, 0.35, 0.127) },

                "wood_latecolor_perlin_enable": { type: "i", value: 1 },
                "wood_latecolor_perlin_bands": { type: "i", value: 1 },
                "wood_latecolor_perlin_weights": { type: "v4", value: new THREE.Vector4(0.75, 0.55, 1, 1) },
                "wood_latecolor_perlin_frequencies": { type: "v4", value: new THREE.Vector4(4.5, 0.05, 1, 1) },
                "wood_late_color_power": { type: "f", value: 1.25 },

                "wood_diffuse_perlin_enable": { type: "i", value: 1 },
                "wood_diffuse_perlin_bands": { type: "i", value: 3 },
                "wood_diffuse_perlin_weights": { type: "v4", value: new THREE.Vector4(0.15, 0.2, 0.05, 1) },
                "wood_diffuse_perlin_frequencies": { type: "v4", value: new THREE.Vector4(0.05, 0.1, 3, 1) },
                "wood_diffuse_perlin_scale_z": { type: "f", value: 0.2 },

                "wood_use_pores": { type: "i", value: 1 },
                "wood_pore_type": { type: "i", value: 0 },
                "wood_pore_radius": { type: "f", value: 0.04 },
                "wood_pore_cell_dim": { type: "f", value: 0.15 },
                "wood_pore_color_power": { type: "f", value: 1.45 },
                "wood_pore_depth": { type: "f", value: 0.02 },

                "wood_use_rays": { type: "i", value: 1 },
                "wood_ray_color_power": { type: "f", value: 1.1 },
                "wood_ray_seg_length_z": { type: "f", value: 5.0 },
                "wood_ray_num_slices": { type: "f", value: 160 },
                "wood_ray_ellipse_z2x": { type: "f", value: 10 },
                "wood_ray_ellipse_radius_x": { type: "f", value: 0.2 },

                "wood_use_latewood_bump": { type: "i", value: 1 },
                "wood_latewood_bump_depth": { type: "f", value: 0.01 },

                "wood_use_groove_roughness": { type: "i", value: 1 },
                "wood_groove_roughness": { type: "f", value: 0.85 },
                "wood_diffuse_lobe_weight": { type: "f", value: 0.9 },

                "wood_curly_distortion_enable": { type: "i", value: 0 },
                "wood_curly_distortion_scale": { type: "f", value: 0.25 },

                "wood_ring_fraction": { type: "v4", value: new THREE.Vector4(0.0, 0.0, 0.0, 0.0) },
                "wood_fall_rise": { type: "v2", value: new THREE.Vector2(0.0, 0.0) },

                "permutationMap": { type: "t", value: null },
                "gradientMap": { type: "t", value: null },
                "perm2DMap": { type: "t", value: null },
                "permGradMap": { type: "t", value: null },

                "importantSamplingRandomMap": { type: "t", value: null },
                "importantSamplingSolidAngleMap": { type: "t", value: null },

                "irradianceMap": { type : "t", value: null },
                "envMap": { type : "t", value: null },
                "exposureBias" : { type:"f", value: 1.0 },
                "envMapExposure" : { type:"f", value: 1.0 },
                "envRotationSin": {type: "f", value: 0.0},
                "envRotationCos": {type: "f", value: 1.0},

                "envExponentMin" : { type:"f", value: 1.0 },
                "envExponentMax" : { type:"f", value: 512.0 },
                "envExponentCount" : { type:"f", value: 10.0 },

                // tiling
                "tilingOverallTransform" : { type: "m4", value: new THREE.Matrix4() },  // TODO OGS has these as 4x4, could be 3x3
                // done above:
                //"TilingMap" : { type : "t", value: null },
                //"TilingMap_texMatrix" : { type: "m3", value: new THREE.Matrix3() },    // NOTE this is 3x3, OGS has it 4x4
                //"TilingNormalMap" : { type : "t", value: null },
                //"TilingNormalMap_texMatrix" : { type: "m3", value: new THREE.Matrix3() },    // NOTE this is 3x3, OGS has it 4x4
                //"TilingRandomMap" : { type : "t", value: null },
                //"TilingRandomMap_texMatrix" : { type: "m3", value: new THREE.Matrix3() },    // NOTE this is 3x3, OGS has it 4x4
                "uv2tile": { type: "v4", value: new THREE.Vector4(1.0, 0.0, 0.0, 1.0) },
                "tile2uv": { type: "v4", value: new THREE.Vector4(1.0, 0.0, 0.0, 1.0) },
                "tileAlignOffset" : { type: "v2", value: new THREE.Vector2(0.0, 0.0) },
                "tilingUVTransform" : { type: "m4", value: new THREE.Matrix4() },  // TODO OGS has these as 4x4, could be 3x3
                "tilingRandomAxisS" : { type: "v2", value: new THREE.Vector2(0.0, 0.0) },
                "tilingRandomAxisT" : { type: "v2", value: new THREE.Vector2(0.0, 0.0) },
                "tilingRandomAlignmentOffset" : { type: "v2", value: new THREE.Vector2(0.0, 0.0) }
                
            }

        ] ),

        vertexShader: prism_vert,
        fragmentShader: prism_frag

    };

THREE.ShaderLib['prism'] = PrismShader;

export let createPrismMaterial = function() {
    var prismMat = createShaderMaterial(PrismShader);
    prismMat.defaultAttributeValues['uvw'] = [0,0,0];
    prismMat.enable3DWoodBump = false;
    prismMat.enableImportantSampling = false;
    prismMat.mapList = {};
    prismMat.isPrismMaterial = true;

    return prismMat;
};

export let clonePrismMaterial = function (mat) {

    var prismMat = createPrismMaterial();

    // this is a dumb way to do what THREE.Material.prototype.clone.call( this, prismMat );
    // would do to create a clone and copy the basic properties. What's the non-stupid way?
    // And why does this material not have its own prototype.clone method?

    prismMat.name = mat.name;

    prismMat.side = mat.side;

    prismMat.opacity = mat.opacity;
    prismMat.transparent = mat.transparent;

    prismMat.blending = mat.blending;

    prismMat.blendSrc = mat.blendSrc;
    prismMat.blendDst = mat.blendDst;
    prismMat.blendEquation = mat.blendEquation;
    prismMat.blendSrcAlpha = mat.blendSrcAlpha;
    prismMat.blendDstAlpha = mat.blendDstAlpha;
    prismMat.blendEquationAlpha = mat.blendEquationAlpha;

    prismMat.depthTest = mat.depthTest;
    prismMat.depthWrite = mat.depthWrite;

    prismMat.polygonOffset = mat.polygonOffset;
    prismMat.polygonOffsetFactor = mat.polygonOffsetFactor;
    prismMat.polygonOffsetUnits = mat.polygonOffsetUnits;

    prismMat.alphaTest = mat.alphaTest;

    prismMat.overdraw = mat.overdraw;

    prismMat.visible = mat.visible;

    // end of the basics shared by all shaders


    prismMat.mapList = mat.mapList;

    prismMat.prismType = mat.prismType;

    //Prism common properties.
    prismMat.surface_albedo = mat.surface_albedo;
    if ( mat.surface_albedo_map !== undefined )
        prismMat.surface_albedo_map = mat.surface_albedo_map;
    prismMat.surface_roughness = mat.surface_roughness;
    if ( mat.surface_roughness_map !== undefined )
        prismMat.surface_roughness_map = mat.surface_roughness_map;
    prismMat.surface_anisotropy = mat.surface_anisotropy;
    if ( mat.surface_anisotropy_map !== undefined )
        prismMat.surface_anisotropy_map = mat.surface_anisotropy_map;
    prismMat.surface_rotation = mat.surface_rotation;
    if ( mat.surface_rotation_map !== undefined )
        prismMat.surface_rotation_map = mat.surface_rotation_map;
    if ( mat.surface_cutout_map !== undefined )
        prismMat.surface_cutout_map = mat.surface_cutout_map;
    if ( mat.surface_normal_map !== undefined )
        prismMat.surface_normal_map = mat.surface_normal_map;

    prismMat.uniforms.importantSamplingRandomMap.value = mat.uniforms.importantSamplingRandomMap.value;
    prismMat.uniforms.importantSamplingSolidAngleMap.value = mat.uniforms.importantSamplingSolidAngleMap.value;

    //Set Prism properties according to the material type.
    switch (prismMat.prismType) {
        case 'PrismOpaque':
            prismMat.opaque_albedo = new THREE.Color().copy(mat.opaque_albedo);
            prismMat.opaque_luminance_modifier = new THREE.Color().copy(mat.opaque_luminance_modifier);
            prismMat.opaque_f0 = mat.opaque_f0;
            prismMat.opaque_luminance = mat.opaque_luminance;

            if ( mat.opaque_albedo_map !== undefined )
                prismMat.opaque_albedo_map = mat.opaque_albedo_map;
            if ( mat.opaque_luminance_modifier_map !== undefined )
                prismMat.opaque_luminance_modifier_map = mat.opaque_luminance_modifier_map;
            if ( mat.opaque_f0_map !== undefined )
                prismMat.opaque_f0_map = mat.opaque_f0_map;

            break;

        case 'PrismMetal':
            prismMat.metal_f0 = new THREE.Color().copy(mat.metal_f0);
            if ( mat.metal_f0_map !== undefined )
                prismMat.metal_f0_map = mat.metal_f0_map;

            break;

        case 'PrismLayered':
            prismMat.layered_f0 = mat.layered_f0;
            prismMat.layered_diffuse = new THREE.Color().copy(mat.layered_diffuse);
            prismMat.layered_fraction = mat.layered_fraction;
            prismMat.layered_bottom_f0 = new THREE.Color().copy(mat.layered_bottom_f0);
            prismMat.layered_roughness = mat.layered_roughness;
            prismMat.layered_anisotropy = mat.layered_anisotropy;
            prismMat.layered_rotation = mat.layered_rotation;

            if ( mat.layered_bottom_f0_map !== undefined )
                prismMat.layered_bottom_f0_map = mat.layered_bottom_f0_map;
            if ( mat.layered_f0_map !== undefined )
                prismMat.layered_f0_map = mat.layered_f0_map;
            if ( mat.layered_diffuse_map !== undefined )
                prismMat.layered_diffuse_map = mat.layered_diffuse_map;
            if ( mat.layered_fraction_map !== undefined )
                prismMat.layered_fraction_map = mat.layered_fraction_map;
            if ( mat.layered_rotationlayered_roughness_map !== undefined )
                prismMat.layered_rotationlayered_roughness_map = mat.layered_rotationlayered_roughness_map;
            if ( mat.layered_anisotropy_map !== undefined )
                prismMat.layered_anisotropy_map = mat.layered_anisotropy_map;
            if ( mat.layered_rotation_map !== undefined )
                prismMat.layered_rotation_map = mat.layered_rotation_map;
            if ( mat.layered_normal_map !== undefined )
                prismMat.layered_normal_map = mat.layered_normal_map;

            break;

        case 'PrismTransparent':
            prismMat.transparent_color = new THREE.Color().copy(mat.transparent_color);
            prismMat.transparent_distance = mat.transparent_distance;
            prismMat.transparent_ior = mat.transparent_ior;

            prismMat.transparent = mat.transparent;
            prismMat.twoPassTransparency = mat.twoPassTransparency;
            break;

        case 'PrismGlazing':
            prismMat.glazing_f0 = new THREE.Color().copy(mat.glazing_f0);
            prismMat.glazing_transmission_color = new THREE.Color().copy(mat.glazing_transmission_color);
            prismMat.glazing_transmission_roughness = mat.glazing_transmission_roughness;

            if ( mat.glazing_f0_map !== undefined )
                prismMat.glazing_f0_map = mat.glazing_f0_map;
            if ( mat.glazing_transmission_color_map !== undefined )
                prismMat.glazing_transmission_color_map = mat.glazing_transmission_color_map;
            if ( mat.glazing_transmission_roughness_map !== undefined )
                prismMat.glazing_transmission_roughness_map = mat.glazing_transmission_roughness_map;

            break;

        case 'PrismWood':
            prismMat.wood_fiber_cosine_enable = mat.wood_fiber_cosine_enable;
            prismMat.wood_fiber_cosine_bands = mat.wood_fiber_cosine_bands;
            prismMat.wood_fiber_cosine_weights = new THREE.Vector4().copy(mat.wood_fiber_cosine_weights);
            prismMat.wood_fiber_cosine_frequencies = new THREE.Vector4().copy(mat.wood_fiber_cosine_frequencies);

            prismMat.wood_fiber_perlin_enable = mat.wood_fiber_perlin_enable;
            prismMat.wood_fiber_perlin_bands = mat.wood_fiber_perlin_bands;
            prismMat.wood_fiber_perlin_weights = new THREE.Vector4().copy(mat.wood_fiber_perlin_weights);
            prismMat.wood_fiber_perlin_frequencies = new THREE.Vector4().copy(mat.wood_fiber_perlin_frequencies);
            prismMat.wood_fiber_perlin_scale_z = mat.wood_fiber_perlin_scale_z;

            prismMat.wood_growth_perlin_enable = mat.wood_growth_perlin_enable;
            prismMat.wood_growth_perlin_bands = mat.wood_growth_perlin_bands;
            prismMat.wood_growth_perlin_weights = new THREE.Vector4().copy(mat.wood_growth_perlin_weights);
            prismMat.wood_growth_perlin_frequencies = new THREE.Vector4().copy(mat.wood_growth_perlin_frequencies);

            prismMat.wood_latewood_ratio = mat.wood_latewood_ratio;
            prismMat.wood_earlywood_sharpness = mat.wood_earlywood_sharpness;
            prismMat.wood_latewood_sharpness = mat.wood_latewood_sharpness;
            prismMat.wood_ring_thickness = mat.wood_ring_thickness;

            prismMat.wood_earlycolor_perlin_enable = mat.wood_earlycolor_perlin_enable;
            prismMat.wood_earlycolor_perlin_bands = mat.wood_earlycolor_perlin_bands;
            prismMat.wood_earlycolor_perlin_weights = new THREE.Vector4().copy(mat.wood_earlycolor_perlin_weights);
            prismMat.wood_earlycolor_perlin_frequencies = new THREE.Vector4().copy(mat.wood_earlycolor_perlin_frequencies);
            prismMat.wood_early_color = new THREE.Color().copy(mat.wood_early_color);

            prismMat.wood_use_manual_late_color = mat.wood_use_manual_late_color;
            prismMat.wood_manual_late_color = new THREE.Color().copy(mat.wood_manual_late_color);

            prismMat.wood_latecolor_perlin_enable = mat.wood_latecolor_perlin_enable;
            prismMat.wood_latecolor_perlin_bands = mat.wood_latecolor_perlin_bands;
            prismMat.wood_latecolor_perlin_weights = new THREE.Vector4().copy(mat.wood_latecolor_perlin_weights);
            prismMat.wood_latecolor_perlin_frequencies = new THREE.Vector4().copy(mat.wood_latecolor_perlin_frequencies);
            prismMat.wood_late_color_power = mat.wood_late_color_power;

            prismMat.wood_diffuse_perlin_enable = mat.wood_diffuse_perlin_enable;
            prismMat.wood_diffuse_perlin_bands = mat.wood_diffuse_perlin_bands;
            prismMat.wood_diffuse_perlin_weights = new THREE.Vector4().copy(mat.wood_diffuse_perlin_weights);
            prismMat.wood_diffuse_perlin_frequencies = new THREE.Vector4().copy(mat.wood_diffuse_perlin_frequencies);
            prismMat.wood_diffuse_perlin_scale_z = mat.wood_diffuse_perlin_scale_z;

            prismMat.wood_use_pores = mat.wood_use_pores;
            prismMat.wood_pore_type = mat.wood_pore_type;
            prismMat.wood_pore_radius = mat.wood_pore_radius;
            prismMat.wood_pore_cell_dim = mat.wood_pore_cell_dim;
            prismMat.wood_pore_color_power = mat.wood_pore_color_power;
            prismMat.wood_pore_depth = mat.wood_pore_depth;

            prismMat.wood_use_rays = mat.wood_use_rays;
            prismMat.wood_ray_color_power = mat.wood_ray_color_power;
            prismMat.wood_ray_seg_length_z = mat.wood_ray_seg_length_z;
            prismMat.wood_ray_num_slices = mat.wood_ray_num_slices;
            prismMat.wood_ray_ellipse_z2x = mat.wood_ray_ellipse_z2x;
            prismMat.wood_ray_ellipse_radius_x = mat.wood_ray_ellipse_radius_x;

            prismMat.wood_use_latewood_bump = mat.wood_use_latewood_bump;
            prismMat.wood_latewood_bump_depth = mat.wood_latewood_bump_depth;

            prismMat.wood_use_groove_roughness = mat.wood_use_groove_roughness;
            prismMat.wood_groove_roughness = mat.wood_groove_roughness;
            prismMat.wood_diffuse_lobe_weight = mat.wood_diffuse_lobe_weight;

            // share common prism DataTextures
            // Note that these are directly stored in the uniforms (see MaterialConverter.convertMaterial)
            prismMat.uniforms.permutationMap.value = mat.uniforms.permutationMap.value;
            prismMat.uniforms.gradientMap.value    = mat.uniforms.gradientMap.value;
            prismMat.uniforms.perm2DMap.value      = mat.uniforms.perm2DMap.value;
            prismMat.uniforms.permGradMap.value    = mat.uniforms.permGradMap.value;

            if ( mat.wood_curly_distortion_map !== undefined )
            {
                prismMat.wood_curly_distortion_map = mat.wood_curly_distortion_map;
                prismMat.wood_curly_distortion_enable = mat.wood_curly_distortion_enable;
                prismMat.wood_curly_distortion_scale = mat.wood_curly_distortion_scale;
            }

            prismMat.wood_ring_fraction = mat.wood_ring_fraction;
            prismMat.wood_fall_rise = mat.wood_fall_rise;

            break;

        default:
            THREE.warn('Unknown prism type: ' + mat.prismType);
    }

    prismMat.envExponentMin = mat.envExponentMin;
    prismMat.envExponentMax = mat.envExponentMax;
    prismMat.envExponentCount = mat.envExponentCount;
    prismMat.envMap = mat.envMap;

    if (mat.useTiling) {
        prismMat.useTiling = mat.useTiling;
        prismMat.tilingOverallTransform = new THREE.Matrix4().copy(mat.tilingOverallTransform);
        prismMat.TilingMap = mat.TilingMap;
        prismMat.TilingMap_texMatrix = new THREE.Matrix3().copy(mat.TilingMap_texMatrix);
        prismMat.hasRoundCorner = mat.hasRoundCorner;
        if ( prismMat.hasRoundCorner ) {
            prismMat.TilingNormalMap = mat.TilingNormalMap;
            prismMat.TilingNormalMap_texMatrix = new THREE.Matrix3().copy(mat.TilingNormalMap_texMatrix);
        }
        prismMat.useRandomOffset = mat.useRandomOffset;
        if ( prismMat.useRandomOffset ) {
            prismMat.TilingRandomMap = mat.TilingRandomMap;
            prismMat.TilingRandomMap_texMatrix = new THREE.Matrix3().copy(mat.TilingRandomMap_texMatrix);
            prismMat.tilingRandomAxisS = new THREE.Vector2().copy(mat.tilingRandomAxisS);
            prismMat.tilingRandomAxisT = new THREE.Vector2().copy(mat.tilingRandomAxisT);
            prismMat.tilingRandomAlignmentOffset = new THREE.Vector2().copy(mat.tilingRandomAlignmentOffset);
        }
        prismMat.uv2tile = mat.uv2tile;
        prismMat.tile2uv = mat.tile2uv;
        prismMat.tilingRepeatRange = [
            mat.tilingRepeatRange[0],
            mat.tilingRepeatRange[1],
            mat.tilingRepeatRange[2],
            mat.tilingRepeatRange[3]
        ];
        prismMat.tileAlignOffset = new THREE.Vector2().copy(mat.tileAlignOffset);
        prismMat.tilingUVTransform = new THREE.Matrix4().copy(mat.tilingUVTransform);
    }

    prismMat.defines = mat.defines;
    return prismMat;
};
