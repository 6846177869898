
import { isTouchDevice } from "../compat";
import { logger } from "../logger/Logger";
import { i18n } from "../globalization/i18next";


/** @constructor */
export function ContextMenu(viewer) {
    this.viewer = viewer;
    this.menus = [];
    this.container = null;
    this.open = false;
}

ContextMenu.prototype.constructor = ContextMenu;

ContextMenu.prototype.show = function(event, menu) {
    var viewport = this.viewer.container.getBoundingClientRect();

    // Normalize Hammer events
    if (Array.isArray(event.changedPointers) && event.changedPointers.length > 0) {
        event.clientX = event.changedPointers[0].clientX;
        event.clientY = event.changedPointers[0].clientY;
    }

    var x = event.clientX - viewport.left;
    var y = event.clientY - viewport.top;

    if (!this.open) {
        this.showMenu(menu, x, y);
        this.open = true;
    }
};

ContextMenu.prototype.showMenu = function (menu, x, y) {

    var menuItem;
    var submenus = [];

    // Create a menu container of the size of the viewer to eat the next click event
    // to close the menu.
    var container = document.createElement('div');
    container.style.left = '0';
    container.style.top = '0';
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.position = 'absolute';
    container.style.zIndex = '10';

    var menuDiv = document.createElement('div');
    menuDiv.classList.add('menu');
    menuDiv.classList.add('docking-panel');
    menuDiv.classList.add('docking-panel-container-solid-color-a');
    container.appendChild(menuDiv);

    this.viewer.container.appendChild(container);
    this.container = container;

    this.menus.push(menuDiv);

    for (var i = 0; i < menu.length; ++i) {
        var defn = menu[i],
            title = defn.title,
            target = defn.target,
            icon = defn.icon,
            shortcut = defn.shortcut;

        menuItem = this.createMenuItem(title, icon, shortcut);
        menuDiv.appendChild(menuItem);

        if (typeof target === 'function') {
            this.addCallbackToMenuItem(menuItem, target);
        } else if (Array.isArray(target)) {
            submenus.push({menuItem: menuItem, target: target});
        } else {
            logger.warn("Invalid context menu option:", title, target);
        }
    }

    var rect = menuDiv.getBoundingClientRect(),
        menuDivWidth = rect.width,
        menuDivHeight = rect.height,
        viewerRect = this.viewer.container.getBoundingClientRect(),
        viewerWidth = viewerRect.width,
        viewerHeight = viewerRect.height,
        shiftLeft = isTouchDevice() && !this.viewer.navigation.getUseLeftHandedInput();

    if (shiftLeft) {
        x -= menuDivWidth;
    }

    if (x < 0) {
        x = 0;
    }
    if (viewerWidth < x + menuDivWidth) {
        x = viewerWidth - menuDivWidth;
        if (x < 0) {
            x = 0;
        }
    }

    if (y < 0) {
        y = 0;
    }
    if (viewerHeight < y + menuDivHeight) {
        y = viewerHeight - menuDivHeight;
        if (y < 0) {
            y = 0;
        }
    }

    menuDiv.style.top = Math.round(y) + "px";
    menuDiv.style.left = Math.round(x) + "px";

    for (i = 0; i < submenus.length; ++i) {
        var submenu = submenus[i];

        menuItem = submenu.menuItem;
        rect = menuItem.getBoundingClientRect();
        x = Math.round((shiftLeft ? rect.left : rect.right) - viewerRect.left);
        y = Math.round(rect.top - viewerRect.top);

        this.addSubmenuCallbackToMenuItem(menuItem, submenu.target, x, y);
    }

    this.OnHide = function(event) {
        if (event.target.className !== "menu-item" && event.button === 0) {
            this.hide(event);
        }
    }.bind(this);

    this.OnMove = function(event) {
        if (event.target.className !== "menu-item") {
            this.hide(event);
            this.show(event, menu);
        }
    }.bind(this);

    this.container.addEventListener('touchend', this.OnHide);
    this.container.addEventListener('click', this.OnHide);
    this.container.addEventListener('contextmenu', this.OnMove);
};

/**
 * @param text - the menu item description
 * @param icon (optional) - className: a CSS class with a content field referencing an icon
 * @param shortcut (optional) - the menu item keyboard shortcut
 * @returns menuItem - div element containing the menu item elements
 */
ContextMenu.prototype.createMenuItem = function(text, icon, shortcut) {
    var menuItem = document.createElement("div");
    menuItem.className = "menu-item";
    shortcut = shortcut || '';

    this.setMenuItemIcon(menuItem, icon);
    this.setMenuItemText(menuItem, text);
    this.setMenuItemShortcut(menuItem, shortcut);

    return menuItem;
};

ContextMenu.prototype.setMenuItemIcon = function(menuItem, iconClass) {
    var menuItemIcon = document.createElement("div");
    menuItemIcon.classList.add("menu-item-icon");

    if (iconClass) {
        menuItemIcon.classList.add(iconClass);
    }

    menuItem.appendChild(menuItemIcon);
};

ContextMenu.prototype.setMenuItemText = function(menuItem, text) {
    var menuItemText = document.createElement("div");
    menuItemText.classList.add("menu-item-text");
    menuItemText.setAttribute("data-i18n", text);
    menuItemText.textContent = i18n.translate( text );
    menuItem.appendChild(menuItemText);
};

ContextMenu.prototype.setMenuItemShortcut = function(menuItem, shortcut) {
    var menuItemShortcut = document.createElement("div");
    menuItemShortcut.classList.add("menu-item-shortcut");
    menuItemShortcut.textContent = shortcut;
    menuItem.appendChild(menuItemShortcut);
};

ContextMenu.prototype.addCallbackToMenuItem = function (menuItem, target) {
    var that = this;

    menuItem.addEventListener('click', function (event) {
        that.hide();
        target();
        event.preventDefault();
        return false;
    }, false);
};

ContextMenu.prototype.addSubmenuCallbackToMenuItem = function (menuItem, menu, x, y) {
    var that = this;

    menuItem.addEventListener('click', function () {
        that.showMenu(menu, x, y);
    }, false);
};

ContextMenu.prototype.hide = function() {
    if (this.open) {
        this.menus = [];
        this.open = false;
        this.container.removeEventListener('touchend', this.OnHide);
        this.container.removeEventListener('click', this.OnHide);
        this.container.removeEventListener('contextmenu', this.OnMove);
        this.container.parentNode.removeChild(this.container);
        this.container = null;
        return true;
    }
    return false;
};
