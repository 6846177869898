"use strict";

import { getGlobal } from "../compat";
import { i18n } from "./i18next";
import { Lang } from "./langs";

export var extendLocalization = function (locales) {
    if (locales !== null && typeof locales === "object") {
        Object.keys(locales).forEach(function(language) {
            i18n.addResourceBundle(
                language,
                "allstrings",
                locales[language],
                true,
                true
            );
        });
        return true;
    }
    return false;
};

export var setLanguage = function (language, callback) {

    var options = {
        lng: language,
        resGetPath: 'res/locales/__lng__/__ns__.json',
        ns: {
            namespaces: ['allstrings'],
            defaultNs: 'allstrings'
        },
        fallbackLng: "en",
        debug: false
    };

    getGlobal().LOCALIZATION_REL_PATH = "res/locales/" + language + "/";
    i18n.init(options, function (t) {
        i18n.clearDebugLocString(); //Calls localize as well
        if (callback) {
            callback();
        }
    });
};


/**
 * Initialize language for localization. The corresponding string files will get downloaded.
 */
export var initializeLocalization = function (options) {
    var language = (options && options.language) || navigator.language;
    var lang = Lang.getSupported(language);
    setLanguage(lang);
};

