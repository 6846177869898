
import { PropertyPanel } from "./PropertyPanel";
import { ViewerPanelMixin } from "./ViewerPanelMixin";
import * as et from "../application/EventTypes";
import { DockingPanel } from "./DockingPanel";


/** @constructor */
export function ViewerPropertyPanel(viewer) {
    this.viewer = viewer;
    this.currentNodeIds = [];
    this.currentModel = null;
    this.isDirty = true;
    this.propertyNodeId = null;
    this.normalTitle = 'Properties';
    this.loadingTitle = 'Object Properties Loading...';

    PropertyPanel.call(this, viewer.container, 'ViewerPropertyPanel', this.loadingTitle);
};

ViewerPropertyPanel.prototype = Object.create(PropertyPanel.prototype);
ViewerPropertyPanel.prototype.constructor = ViewerPropertyPanel;
ViewerPanelMixin.call( ViewerPropertyPanel.prototype );

function isSolidWorks(model) {
    var docNode = model.getDocumentNode();
    var viewable = docNode && docNode.findViewableParent();

    if (viewable && viewable.name().toLocaleLowerCase().indexOf(".sld") !== -1) {
        return true;
    }

    return false;
}

ViewerPropertyPanel.prototype.initialize = function () {
    PropertyPanel.prototype.initialize.call(this);

    var that = this;

    that.addEventListener(that.viewer, et.AGGREGATE_SELECTION_CHANGED_EVENT, function (event) {

        if (event.selections && event.selections.length) {
            that.currentNodeIds = event.selections[0].dbIdArray;
            that.currentModel = event.selections[0].model;
        } else {
            that.resetCurrentModel();
        }

        that.isDirty = true;
        that.requestProperties();
    });

    that.addEventListener(that.viewer, et.HIDE_EVENT, function (e) {
        that.isDirty = true;
        that.requestProperties();
    });


    // Make sure that props are refreshed if instanceTree was not available before.
    that.addEventListener(that.viewer, et.OBJECT_TREE_CREATED_EVENT, function (e) {
        if (that.currentModel === e.model) {
            that.isDirty = true;
            that.requestProperties();
        }
    });

    that.addEventListener(that.viewer, et.SHOW_EVENT, function (e) {
        that.isDirty = true;
        that.requestProperties();
    });

    // Populate the ids with the current selection.
    //
    var aggregateSelection = this.viewer.getAggregateSelection();
    if (aggregateSelection.length) {
        this.currentModel = aggregateSelection[0].model;
        this.currentNodeIds = aggregateSelection[0].selection;
    } else {
        this.resetCurrentModel();
    }

};

// Reset current model to the only visible one (or null if there is no unique visible model)
ViewerPropertyPanel.prototype.resetCurrentModel = function() {
    // If only a single model is visible, show model props by default
    var visibleModels = this.viewer ? this.viewer.getVisibleModels() : [];
    if (visibleModels.length === 1) {
        this.currentModel = visibleModels[0];
        this.currentNodeIds = [];
    } else {
        this.currentModel = null;
        this.currentNodeIds = [];
    }
};

ViewerPropertyPanel.prototype.setTitle = function (title, options) {
    if (!title) {
        title = 'Object Properties';  // localized by DockingPanel.prototype.setTitle
        options = options || {};
        options.localizeTitle = true;
    }
    PropertyPanel.prototype.setTitle.call(this, title, options);
};

ViewerPropertyPanel.prototype.setVisible = function (show) {
    DockingPanel.prototype.setVisible.call(this, show);
    this.requestProperties();
};

ViewerPropertyPanel.prototype.visibilityChanged = function() {
    DockingPanel.prototype.visibilityChanged.call(this);
    if (this.isVisible())
        this.requestProperties();
};

ViewerPropertyPanel.prototype.requestProperties = function () {
    if (this.isVisible() && this.isDirty) {
        if (this.currentModel && this.currentNodeIds.length > 0) {
            this.setNodeProperties(this.currentNodeIds[this.currentNodeIds.length - 1]);
        } else {
            this.showDefaultProperties();
        }
        this.isDirty = false;
    }
};

ViewerPropertyPanel.prototype.setNodeProperties = function (nodeId) {
    var that = this;
    this.propertyNodeId = nodeId;

    // Remember for which model we do the query. Note that this.currentModel may change
    // before getProperties() invokes the callback. 
    var model = this.currentModel;

    model.getProperties(nodeId, function (result) {

        // Prevent trying to make changes after dialog was uninitialized.
        if (!that.viewer)
            return;

        // Ignore callback if outdated: Another id/model may have been selected meanwhile.
        // Note that that.currentModel may also be null meanwhile if selection was cleared
        // and the number of visible models is !=1.
        if (model !== that.currentModel || nodeId !== that.propertyNodeId) {
            return;
        }

        // Handle __internalref__ properties to support Solidworks
        // And for finding Revit sheet properties, which are children
        // of the root model properties.
        var internalRefIds = [];
        var props = result.properties;
        for (var i=0, len=props.length; i<len; ++i) {
            var prop = props[i];
            if (prop.displayCategory === "__internalref__") {
                internalRefIds.push(prop.displayValue);
            }
        }

        var prom;
        if (model.is3d() && isSolidWorks(model) && internalRefIds.length > 0) {
            // Solidworks or similar file type containing a Configuration __internalref__
            // Get the properties of all the internalref nodes and merge them with the properties already fetched.
            prom = fetchAndMerge(model, internalRefIds, result);
        } /*else if (model.is2d() && !that.isSelection) {
            // for sheets return only the sheet properties, if we can find them
            // and if it is not a selection
            prom = getSheetProperties(model, internalRefIds);
        }*/
        else {
            //All other cases, just return the node properties
            prom = Promise.resolve(result);
        }

        prom.then(function(result){
            var title = result.name || that.normalTitle;
            var doLocalize = !result.name;
            that.setTitle(title, {localizeTitle: doLocalize});
            if ('name' in result && result.properties) {
                // name is displayed in the title,but ctrl/cmd+c doesn't work there
                // So we include it again in the property list
                result.properties.splice(0, 0, {
                    "displayName": 'Name',
                    "displayValue": result.name,
                    "displayCategory": null,
                    "attributeName": 'Name',
                    "type": 20,
                    "units": null,
                    "hidden": false,
                    "precision": 0
                });
            }
            that.setProperties(result.properties);
            that.highlight(that.viewer.searchText);
    
            that.resizeToContent();
            that.respositionPanel();
        }).catch(function(){
            that.setProperties([]);
            that.highlight('');
        
            that.resizeToContent();
            that.respositionPanel();
        });
    },
    function onError() {
        that.setTitle(that.normalTitle, {localizeTitle: true});
        that.showNoProperties();
    });
};

/*
Call this method when the current model is a sheet and its default properties are required.
If the sheet could not be found, result is null.
 */
function getSheetProperties(model, dbIds) {
    return new Promise(function(resolve, reject){
        // given sheet name of the 2d model
        var sheetName = model.myData.metadata.title;
        model.getBulkProperties(dbIds, {ignoreHidden: true}, function(bulkResults){

            for (var x=0, xLen=bulkResults.length; x<xLen; ++x) {
                var result = bulkResults[x];
                // property name occurs in the sheet name
                if (result.name && sheetName.indexOf(result.name) !== -1) {

                    for (var i=0, len=result.properties.length; i<len; ++i) {
                        var prop = result.properties[i];
                        // sheet number additionally occurs in the sheet name
                        if (prop.displayName === 'Sheet Number' && sheetName.indexOf(prop.displayValue) !== -1) {
                            return resolve(result);
                        }
                    }
                }
            }

            return resolve(null);
        });
    });
}

function fetchAndMerge(model, dbIds, previousResult) {
    return new Promise(function(resolve, reject){
        model.getBulkProperties(dbIds, {ignoreHidden: true}, function(bulkResults){
            for (var x=0, xLen=bulkResults.length; x<xLen; ++x) {
                var result = bulkResults[x];
                // Merge additional properties 
                for (var i=0, len=result.properties.length; i<len; ++i) {
                    var prop = result.properties[i];
                    // Only merge new properties
                    var isNewProperty = true;
                    for (var j=0, len2=previousResult.properties.length; j<len2; ++j) {
                        if (previousResult.properties[j].displayName === prop.displayName) {
                            isNewProperty = false;
                            j = len2; // aka: break;
                        }
                    }
                    if (isNewProperty) {
                        previousResult.properties.push(prop);
                    }
                }
            }
            resolve(previousResult);
        });
    });
}

ViewerPropertyPanel.prototype.respositionPanel = function() {
    
    if (!this.isVisible())
        return;

    // Does the property panel overlap the mouse position? If so, then reposition
    // the property panel. Prefer a horizontal vs. vertical reposition.
    //
    var toolController = this.viewer.toolController,
    mx = toolController.lastClickX,
    my = toolController.lastClickY,
    panelRect = this.container.getBoundingClientRect(),
    px = panelRect.left,
    py = panelRect.top,
    pw = panelRect.width,
    ph = panelRect.height,
    canvasRect = this.viewer.impl.getCanvasBoundingClientRect(),
    cx = canvasRect.left,
    cy = canvasRect.top,
    cw = canvasRect.width,
    ch = canvasRect.height;

    if ((px <= mx && mx < px + pw) && (py <= my && my < py + ph)) {
        if ((mx < px + (pw / 2)) && (mx + pw) < (cx + cw)) {
            this.container.style.left = Math.round(mx - cx) + 'px';
            this.container.dockRight = false;
        } else if (cx <= (mx - pw)) {
            this.container.style.left = Math.round(mx - cx - pw) + 'px';
            this.container.dockRight = false;
        } else if ((mx + pw) < (cx + cw)) {
            this.container.style.left = Math.round(mx - cx) + 'px';
            this.container.dockRight = false;
        } else if ((my + ph) < (cy + ch)) {
            this.container.style.top = Math.round(my - cy) + 'px';
            this.container.dockBottom = false;
        } else if (cy <= (my - ph)) {
            this.container.style.top = Math.round(my - cy - ph) + 'px';
            this.container.dockBottom = false;
        }
    }
};

ViewerPropertyPanel.prototype.showDefaultProperties = function () {
    var rootId = this.currentModel ? this.currentModel.getRootId() : null;
    if (rootId) {
        this.setNodeProperties(rootId);
    } else {
        this.propertyNodeId = null;
        this.setTitle(this.normalTitle, {localizeTitle: true});  // localized by DockingPanel.prototype.setTitle
        PropertyPanel.prototype.showDefaultProperties.call(this);
    }
};

ViewerPropertyPanel.prototype.areDefaultPropertiesShown = function () {
    if (!this.currentModel)
        return false;
    var rootId = this.currentModel.getRootId();
    return this.propertyNodeId === rootId;
};

ViewerPropertyPanel.prototype.uninitialize = function () {
    PropertyPanel.prototype.uninitialize.call(this);
    this.viewer = null;
};

ViewerPropertyPanel.prototype.onCategoryClick = function (category, event) {
    PropertyPanel.prototype.onCategoryClick.call(this, category, event);
    this.resizeToContent();
};

ViewerPropertyPanel.prototype.onCategoryIconClick = function (category, event) {
    PropertyPanel.prototype.onCategoryIconClick.call(this, category, event);
    this.resizeToContent();
};
