
import * as THREE from "three";
import { SnapType } from "./SnapTypes";
import { MeasurementTypes } from "./MeasurementTypes";
import { convertUnits, formatValueWithUnits } from "./UnitFormatter";


    export const EPSILON = 0.0001;

    export function getSnapResultPosition(pick, viewer) {
        if (!pick) {
            return null;
        }

        if (pick.isPerpendicular) {
            return pick.intersectPoint;
        }

        switch (pick.geomType) {
            case SnapType.SNAP_VERTEX:
            case SnapType.SNAP_MIDPOINT:
            case SnapType.SNAP_CIRCLE_CENTER:
                return pick.getGeometry();

            case SnapType.SNAP_EDGE:
                var eps = getEndPointsInEdge(pick.getGeometry());
                var p1 = eps[0].clone();
                var p2 = eps[1].clone();
                return nearestPointInPointToLine(pick.intersectPoint,p1, p2);

            case SnapType.SNAP_FACE:
                return nearestPointInPointToPlane(pick.intersectPoint, pick.getGeometry().vertices[0], pick.faceNormal);

            case SnapType.SNAP_CIRCULARARC:
                if (viewer && viewer.model && viewer.model.is2d()) {
                    var point = nearestVertexInVertexToEdge(pick.intersectPoint, pick.getGeometry());
                    pick.geomType = SnapType.SNAP_VERTEX;
                    pick.geomVertex = point;
                    return point;

                } else {
                    // For 3D models, currently we don't have the center geometry of the circle. 
                    // So the only way to select the center is by selecting the perimeter.
                    return pick.circularArcCenter;    
                }

            case SnapType.SNAP_CURVEDEDGE:
                return nearestVertexInVertexToEdge(pick.intersectPoint, pick.getGeometry());

            case SnapType.SNAP_CURVEDFACE:
                return pick.intersectPoint;

            default:
                return null;
        }
    };

    export function correctPerpendicularPicks(firstPick, secondPick, viewer, snapper) {

        if (!firstPick || !secondPick || !firstPick.getGeometry() || !secondPick.getGeometry()) {
            return false;
        }

        var start = getSnapResultPosition(firstPick, viewer);

        if (snapper && viewer) {

            // Simple _ to Edge - Snap the second pick when it's 90 degrees.
            if (secondPick.geomType === SnapType.SNAP_EDGE) {
                var v2 = new THREE.Vector3();
                var v3 = new THREE.Vector3();
                
                var secondEdge = secondPick.getGeometry();

                v2.subVectors(secondPick.intersectPoint, start).normalize(); // rubberband vector
                v3.subVectors(secondEdge.vertices[0], secondEdge.vertices[1]).normalize();

                if(isPerpendicular(v2, v3, 0.05)) {
                    
                    var newPoint = nearestPointInPointToSegment(start, secondEdge.vertices[0], secondEdge.vertices[1], true);
                    
                    if (newPoint) {
                        if (snapper.onMouseMove(project(newPoint, viewer))) {
                            snapper.setPerpendicular(true);
                        }
                        
                        secondPick.geomVertex = newPoint;
                        secondPick.intersectPoint = newPoint;
                        return true;
                    }
                }
            }

            // Simple _ to Face - Snap the second pick when it's 90 degrees.
            else if (secondPick.geomType === SnapType.SNAP_FACE) {

                var v = new THREE.Vector3();
                
                var secondFace = secondPick.getGeometry();

                v.subVectors(secondPick.intersectPoint, start).normalize(); // rubberband vector

                if(isParallel(secondPick.faceNormal, v, 0.05)) {
                    
                    var newPoint = nearestPointInPointToPlane(start, secondFace.vertices[0], secondPick.faceNormal);
                    if (snapper.onMouseMove(project(newPoint, viewer))) {
                        snapper.setPerpendicular(true);
                    }

                    secondPick.geomVertex = newPoint;
                    secondPick.intersectPoint = newPoint;
                    return true;
                }
            }
        }
    };

    export function calculateDistance(firstPick, secondPick, dPrecision, viewer) {

        if (!firstPick || !secondPick || !firstPick.getGeometry() || !secondPick.getGeometry()) {
            return null;
        }

        var ep1 = getSnapResultPosition(firstPick, viewer);
        var ep2 = getSnapResultPosition(secondPick, viewer);

        if (!ep1 || !ep2) {
            return null;
        }

        if (isEqualVectors(ep1, ep2, EPSILON)) {
            return null;
        }

        var distanceXYZ, distanceX, distanceY, distanceZ;

        // Convert coords when in 2D
        if (viewer.model.is2d()) {
            ep1 = ep1.clone();
            ep2 = ep2.clone();
            viewer.model.pageToModel(ep1, ep2, firstPick.viewportIndex2d);
        }

        // Include resolution limits for high precision 2D-measurements, where available 
        if (dPrecision) {

            var n = Math.log(2.0 * dPrecision) / Math.log(10.0);
            var nd = Math.floor(n);

            // Increase one decimal to ensure being covered
            if (1.0 < (2.0 * dPrecision / Math.pow(10.0, nd)))
                nd++;
                
            dPrecision = Math.pow(10.0, nd);

            // Adjust the distances aligned by the precision
            var measurementDistance = ep1.distanceTo(ep2);
            var m = measurementDistance / dPrecision;
            distanceXYZ = Math.floor(m + 0.5) * dPrecision;
            
            measurementDistance = Math.abs(ep1.x - ep2.x);
            m = measurementDistance / dPrecision;
            distanceX = Math.floor(m + 0.5) * dPrecision;

            measurementDistance = Math.abs(ep1.y - ep2.y);
            m = measurementDistance / dPrecision;
            distanceY = Math.floor(m + 0.5) * dPrecision;

            return {
                distanceXYZ: distanceXYZ,
                distanceX: distanceX,
                distanceY: distanceY,
                distanceZ: 0,
                type: MeasurementTypes.MEASUREMENT_DISTANCE
            };
        }

        // Calculation for 3D models and 2D models without resolution limits
        distanceXYZ = ep1.distanceTo(ep2);
        distanceX = Math.abs(ep1.x - ep2.x);
        distanceY = Math.abs(ep1.y - ep2.y);
        distanceZ = Math.abs(ep1.z - ep2.z);
        return {
            distanceXYZ: distanceXYZ,
            distanceX: distanceX,
            distanceY: distanceY,
            distanceZ: distanceZ,
            type: MeasurementTypes.MEASUREMENT_DISTANCE
        };
    };

    function getDpiPrecision(model, viewportIndex) {

        // Only do this for 2D models.
        if (model.is3d()) {
            return 0;
        }

        // Include resolution limits for high precision 2D-measurements, where available (TREX-575)
        var page_width = model.getMetadata('page_dimensions', 'page_width', null);
        var logical_width = model.getMetadata('page_dimensions', 'logical_width', null);
        var page_height = model.getMetadata('page_dimensions', 'page_height', null);
        var logical_height = model.getMetadata('page_dimensions', 'logical_height', null);
        if (!page_width || !logical_width || !page_height || !logical_height) {
            return 0;
        }

        // Retrieve the inverse DPI
        var invdpix = page_width / logical_width;
        var invdpiy = page_height / logical_height;
        
        // Calculate the graininess in model units
        var p1 = new THREE.Vector3(0.0, 0.0, 0.0);
        var p2 = new THREE.Vector3(invdpix, invdpiy, 0.0);
        model.pageToModel(p1, p2, viewportIndex);
        var dPrecision = p1.distanceTo(p2);

        return dPrecision;
    };

    function isContainsEqualVectors(points) {
        for (var i = 0; i < points.length; i++) {
            for (var j = 0; j < points.length; j++) {
                if (i !== j && isEqualVectors(points[i], points[j], EPSILON)) {
                    return true;
                }
            }
        }

        return false;
    };

    function calculateAngle(picks, viewer) {
        var points = [];

        for (var key in picks) {
            if (picks.hasOwnProperty(key)) {
                var point = getSnapResultPosition(picks[key], viewer);
                if (point) {
                    points.push(point);    
                }
            }            
        }

        if (points.length !== 3 || isContainsEqualVectors(points)) {
            return null;
        }


        var v1 = new THREE.Vector3();
        var v2 = new THREE.Vector3();

        v1.subVectors(points[0], points[1]);
        v2.subVectors(points[2], points[1]);

        return angleVectorToVector(v1, v2);
    };
    
    function calculateArea(picks, viewer) {
        var points = [];

        for (var key in picks) {
            if (picks.hasOwnProperty(key)) {
                var point = getSnapResultPosition(picks[key], viewer);
                if (point) {
                    points.push(point.clone());    
                }
            }            
        }

        var firstPoint = getSnapResultPosition(picks[1], viewer);
        if (firstPoint) {
            points.push(firstPoint.clone());
        }

        for (var i = 0; i < points.length; i+=2) {
            viewer.model.pageToModel(points[i], points[i + 1], picks[1].viewportIndex2d);
        }

        var sum1 = 0;
        var sum2 = 0;

        for (var i = 0; i < points.length - 1; i++) {
            sum1 += points[i].x * points[i+1].y;
            sum2 += points[i].y * points[i+1].x;
        }

        var area = Math.abs((sum1 - sum2) / 2);
        return area;
        
    };

    /**
     * The main function for this file, which calculates a measurement result (either distance
     * or angle) from a given measurement.
     */
    export function computeResult(picks, measurementType, viewer) {

        switch (measurementType) {
            case MeasurementTypes.MEASUREMENT_DISTANCE:
                var firstPick = picks[1];
                var secondPick = picks[2];
                var dPrecision = getDpiPrecision(viewer.model, firstPick.viewportIndex2d);

                return calculateDistance(firstPick, secondPick, dPrecision, viewer);
            
            case MeasurementTypes.MEASUREMENT_ANGLE:
                var angle = calculateAngle(picks, viewer);
                return angle ? {
                        angle: angle,
                        type: measurementType
                    } : null;

            case MeasurementTypes.MEASUREMENT_AREA:
                return {
                    area: calculateArea(picks, viewer),
                    type: measurementType
                };

            default:
                return null;

        }
    };

    export function getFaceArea(viewer, measurement, face, units, precision, calibrationFactor) {

        var area = 0;
        var vertices = face.vertices;
        var V1 = new THREE.Vector3();
        var V2 = new THREE.Vector3();

        for (var i = 0; i < vertices.length; i += 3) {

            V1.subVectors(vertices[i + 1], vertices[i]);
            V2.subVectors(vertices[i + 2], vertices[i]);

            area += V1.length() * V2.length() * Math.sin(V1.angleTo(V2)) / 2;
        }

        area = convertUnits(viewer.model.getUnitString(), units, calibrationFactor, area, 'square');

        if (units) {

            return formatValueWithUnits(area, units+'^2', 3, precision);
        }
        else {

            return formatValueWithUnits(area, null, 3, precision);
        }

    };

    export function getCircularArcRadius(viewer, measurement, edge, units, precision, calibrationFactor) {

        var radius = edge.radius;

        if (radius) {
            if (viewer.model.is2d()) {
                var pt1 = edge.center.clone();
                var pt2 = edge.vertices[0].clone();
                viewer.model.pageToModel(pt1, pt2, measurement.getPick(1).viewportIndex2d);
                radius = pt1.distanceTo(pt2);
            }

            radius = convertUnits(viewer.model.getUnitString(), units, calibrationFactor, radius);
            return formatValueWithUnits(radius, units, 3, precision);
        }
    };

    export function project(point, viewer, offset) {
        var camera = viewer.navigation.getCamera(),
            containerBounds = viewer.navigation.getScreenViewport(),
            p = new THREE.Vector3(point.x, point.y, point.z);

        p = p.project(camera);

        offset = offset || 0;

        return new THREE.Vector2(Math.round(( p.x + 1) / 2 * containerBounds.width) + offset,
                Math.round((-p.y + 1) / 2 * containerBounds.height) + offset);
    };

    export function inverseProject(point, viewer) {

        var camera = viewer.navigation.getCamera(),
            containerBounds = viewer.navigation.getScreenViewport(),
            p = new THREE.Vector3();

        p.x = point.x / containerBounds.width * 2 - 1;
        p.y = -(point.y / containerBounds.height * 2 - 1);
        p.z = 0;

        p = p.unproject(camera);

        return p;
    };

    //***** Helper functions for calculations without state: ***** //
//TODO_TS: Are these used outside the Markup extension? If not, they should move there.
    

    // Get the nearest point on the line from point to line
    // inLine - whether to force the result to be inside the given line or not.
    export function nearestPointInPointToLine(point, lineStart, lineEnd) {

        var X0 = new THREE.Vector3();
        var X1 = new THREE.Vector3();
        var nearestPoint;
        var param;

        X0.subVectors(lineStart, point);
        X1.subVectors(lineEnd, lineStart);
        param = X0.dot(X1);
        X0.subVectors(lineEnd, lineStart);
        param = -param / X0.dot(X0);

        X0.subVectors(lineEnd, lineStart);
        X0.multiplyScalar(param);
        nearestPoint = X0.add(lineStart);    

        return nearestPoint;
    };

    // Get the nearest point on the line segment from point to line segment
    export function nearestPointInPointToSegment(point, lineStart, lineEnd, forcePerpendicular) {

        var X0 = new THREE.Vector3();
        var X1 = new THREE.Vector3();
        var nearestPoint;
        var param;

        X0.subVectors(lineStart, point);
        X1.subVectors(lineEnd, lineStart);
        param = X0.dot(X1);
        X0.subVectors(lineEnd, lineStart);
        param = -param / X0.dot(X0);

        if (param < 0) {
            if (forcePerpendicular) {
                nearestPoint = null;
            } else {
                nearestPoint = lineStart;    
            }
        }
        else if (param > 1) {
            if (forcePerpendicular) {
                nearestPoint = null;
            } else {
                nearestPoint = lineEnd;    
            }
        }
        else {
            X0.subVectors(lineEnd, lineStart);
            X0.multiplyScalar(param);
            nearestPoint = X0.add(lineStart);
        }

        return nearestPoint;
    };
        
        

    export function isEqualVectors(v1, v2, precision) {
        if (!v1 || !v2) {
            return false;
        }

        if (Math.abs(v1.x - v2.x) <= precision && Math.abs(v1.y - v2.y) <= precision && Math.abs(v1.z - v2.z) <= precision) {
            return true;
        }
        return false;
    };

    export function isInverseVectors(v1, v2, precision) {
        if (Math.abs(v1.x + v2.x) <= precision && Math.abs(v1.y + v2.y) <= precision && Math.abs(v1.z + v2.z) <= precision) {
            return true;
        }
        return false;
    };

    export function getEndPointsInEdge(edge) {

        var vertices = edge.vertices;
        var endPoints = [];

        for (var i = 0; i < vertices.length; ++i) {

            var duplicate = false;

            for (var j = 0; j < vertices.length; ++j) {

                if (j !== i && vertices[j].equals(vertices[i])) {

                    duplicate = true;
                    break;
                }
            }

            if (!duplicate) {

                endPoints.push(vertices[i]);

            }
        }

        return endPoints;
    };

    export function angleVectorToVector(v1, v2) {
        return v1.angleTo(v2) * 180 / Math.PI;
    };

    // Get the two nearest endpoints between two line segments
    export function nearestPointsInSegmentToSegment(p1, p2, p3, p4) {

        
        var u = new THREE.Vector3();
        var v = new THREE.Vector3();
        var w = new THREE.Vector3();

        u.subVectors(p2, p1);
        v.subVectors(p4, p3);
        w.subVectors(p1, p3);

        var a = u.dot(u);
        var b = u.dot(v);
        var c = v.dot(v);
        var d = u.dot(w);
        var e = v.dot(w);
        var D = a * c - b * b;
        var sc, sN, sD = D;
        var tc, tN, tD = D;

        // Compute the line parameters of the two closest points
        if (D < EPSILON) { // the lines are almost parallel
            sN = 0.0;  // for using point p1 on segment p1p2
            sD = 1.0;  // to prevent possible division by 0.0 later
            tN = e;
            tD = c;
        }
        else {  // get the closest points on the infinite lines
            sN = b * e - c * d;
            tN = a * e - b * d;
            if (sN < 0.0) {  // sc < 0 => the s = 0 is visible
                sN = 0.0;
                tN = e;
                tD = c;
            }
            else if (sN > sD) {  // sc > 1 => the s = 1 edge is visible
                sN = sD;
                tN = e + b;
                tD = c;
            }
        }

        if (tN < 0.0) {  // tc < 0 => the t = 0 edge is visible
            tN = 0.0;
            // recompute sc for this edge
            if (-d < 0.0)
                sN = 0.0;
            else if (-d > a)
                sN = sD;
            else {
                sN = -d;
                sD = a;
            }
        }
        else if (tN > tD) {  // tc > 1 => the t = 1 edge is visible
            tN = tD;
            // recompute sc for this edge
            if ((-d + b) < 0.0)
                sN = 0;
            else if ((-d + b) > a)
                sN = sD;
            else {
                sN = -d + b;
                sD = a;
            }
        }

        // finally do the division to get sc and tc
        sc = Math.abs(sN) < EPSILON ? 0.0 : sN / sD;
        tc = Math.abs(tN) < EPSILON ? 0.0 : tN / tD;

        // get the difference of the two closest points
        u.multiplyScalar(sc);
        v.multiplyScalar(tc);
        w.add(u);
        w.sub(v);

        //return w.length();

        u.add(p1);
        v.add(p3);
        return [u, v];
    };


    // Find the nearest point from point to plane
    function nearestPointInPointToPlane(p1, p2, n) {

        var nearestPoint = new THREE.Vector3();
        var norm = n.clone();
        var X0 = new THREE.Vector3();
        X0.subVectors(p1, p2);

        var sn = -norm.dot(X0);
        var sd = norm.dot(norm);
        var sb = sn / sd;

        nearestPoint.addVectors(p1, norm.multiplyScalar(sb));
        return nearestPoint;
    };



    // Returns true if v1 an v2 are parallel
    export function isParallel(v1, v2, precision) {
        precision = precision ? precision : EPSILON;
        return 1 - Math.abs(v1.dot(v2)) < precision;
    };

    export function isPerpendicular(v1, v2, precision) {
        precision = precision ? precision : EPSILON;
        return Math.abs(v1.dot(v2)) < precision;
    };


    // Find the vertex need to draw For circular arc's radius
    function nearestVertexInVertexToEdge(vertex, edge) {

        var nearestPoint;
        var minDist = Number.MAX_VALUE;

        for (var i = 0; i < edge.vertices.length; i++) {
            var dist = vertex.distanceTo(edge.vertices[i]);
            if (minDist > dist) {
                nearestPoint = edge.vertices[i];
                minDist = dist;
            }
        }

        return nearestPoint;
    };


    // Helper to iterator over all properties in an object
//TODO_TS: Does this really need to be an official export of MeasureCommon?
    export function forAll(obj, func) {
        if (obj) {
            for (var i in obj) {
                if (obj.hasOwnProperty(i)) {
                    var m = obj[i];
                    if (m)
                        func(m);
                }
            }
        }
    }

    export function createCommonOverlay(viewer, name) {
        if (!viewer.impl.overlayScenes[name])
            viewer.impl.createOverlayScene(name);
    }

    export function safeToggle(element, property, show) {
        // toggle only if it needs to. Necessary for IE11.

        if ((element.classList.contains(property) && !show) || (!element.classList.contains(property) && show)) {
            element.classList.toggle(property, show);    
        }
    }

    // Centroid of a polygon is the average of its points.
    export function getCentroidOfPolygon(points) {
        var centroid = new THREE.Vector3();
        var n = points.length;

        for (var i = 0; i < n; i++) {
            centroid.add(points[i]);
        }

        centroid.multiplyScalar(1 / n);
        return centroid;
    }

    // Algorithm taken from here:
    // https://www.codeproject.com/Tips/862988/Find-the-Intersection-Point-of-Two-Line-Segments
    function isSegmentsIntersect(p1, p2, q1, q2) {
        var r = new THREE.Vector3();
        var s = new THREE.Vector3();
        var rxs = new THREE.Vector3();
        var tmp = new THREE.Vector3();

        r.subVectors(p2, p1);
        s.subVectors(q2,q1);
        rxs.crossVectors(r, s);

        // The two lines are parallel and non-intersecting.
        if (Math.abs(rxs.z) < EPSILON)
            return false;

        var t = tmp.subVectors(q1, p1).cross(s).z / rxs.z;
        var u = tmp.subVectors(q1, p1).cross(r).z / rxs.z;

        // the two line segments meet at the point p + t r = q + u s.
        if (!(Math.abs(rxs.z) < EPSILON) && (0 <= t && t <= 1) && (0 <= u && u <= 1))
        {
            // An intersection was found.
            return true;
        }

        // 5. Otherwise, the two line segments are not parallel but do not intersect.
        return false;
    };

    // A polygon is a simple polygon if there are no intersection between its edges.
    export function isPolygonSimple(points) {
        var n = points.length;

        if (n === 3) {
            return true;
        }

        for (var i = 0; i < n; i++) {
            
            var p1 = points[i];
            var p2 = points[(i + 1) % n];

            for (var j = i + 2; j < n; j++) {

                var q1 = points[(j) % n];
                var q2 = points[(j + 1) % n];

                if (q2 === p1) {
                    break;
                }

                if (isSegmentsIntersect(p1, p2, q1, q2)) {
                    return false;
                }
            }
        }

        return true;
    };

    // Algorithm based on https://github.com/mapbox/polylabel
    export function getPolygonVisualCenter(polygon) {
        function compareMax(a, b) {
            return b.max - a.max;
        }

        function Cell(x, y, h, polygon) {
            this.x = x; // cell center x
            this.y = y; // cell center y
            this.h = h; // half the cell size
            this.d = pointToPolygonDist(x, y, polygon); // distance from cell center to polygon
            this.max = this.d + this.h * Math.SQRT2; // max distance to polygon within a cell
        }

        // signed distance from point to polygon outline (negative if point is outside)
        function pointToPolygonDist(x, y, polygon) {
            var inside = false;
            var minDistSq = Infinity;

            for (var k = 0; k < polygon.length; k++) {
                var ring = polygon[k];

                for (var i = 0, len = ring.length, j = len - 1; i < len; j = i++) {
                    var a = ring[i];
                    var b = ring[j];

                    if ((a.y > y !== b.y > y) &&
                        (x < (b.x - a.x) * (y - a.y) / (b.y - a.y) + a.x)) inside = !inside;

                    minDistSq = Math.min(minDistSq, getSegDistSq(x, y, a, b));
                }
            }

            return (inside ? 1 : -1) * Math.sqrt(minDistSq);
        }

        // get polygon centroid
        function getCentroidCell(polygon) {
            var area = 0;
            var x = 0;
            var y = 0;
            var points = polygon[0];

            for (var i = 0, len = points.length, j = len - 1; i < len; j = i++) {
                var a = points[i];
                var b = points[j];
                var f = a.x * b.y - b.x * a.y;
                x += (a.x + b.x) * f;
                y += (a.y + b.y) * f;
                area += f * 3;
            }
            if (area === 0) return new Cell(points[0].x, points[0].y, 0, polygon);
            return new Cell(x / area, y / area, 0, polygon);
        }

        // get squared distance from a point to a segment
        function getSegDistSq(px, py, a, b) {

            var x = a.x;
            var y = a.y;
            var dx = b.x - x;
            var dy = b.y - y;

            if (dx !== 0 || dy !== 0) {

                var t = ((px - x) * dx + (py - y) * dy) / (dx * dx + dy * dy);

                if (t > 1) {
                    x = b.x;
                    y = b.y;

                } else if (t > 0) {
                    x += dx * t;
                    y += dy * t;
                }
            }

            dx = px - x;
            dy = py - y;

            return dx * dx + dy * dy;
        }

        function TinyQueue(data, compare) {

            function defaultCompare(a, b) {
                return a < b ? -1 : a > b ? 1 : 0;
            }

            if (!(this instanceof TinyQueue)) return new TinyQueue(data, compare);

            this.data = data || [];
            this.length = this.data.length;
            this.compare = compare || defaultCompare;

            if (this.length > 0) {
                for (var i = (this.length >> 1); i >= 0; i--) this._down(i);
            }
        }

        TinyQueue.prototype = {

            push: function (item) {
                this.data.push(item);
                this.length++;
                this._up(this.length - 1);
            },

            pop: function () {
                if (this.length === 0) return undefined;

                var top = this.data[0];
                this.length--;

                if (this.length > 0) {
                    this.data[0] = this.data[this.length];
                    this._down(0);
                }
                this.data.pop();

                return top;
            },

            peek: function () {
                return this.data[0];
            },

            _up: function (pos) {
                var data = this.data;
                var compare = this.compare;
                var item = data[pos];

                while (pos > 0) {
                    var parent = (pos - 1) >> 1;
                    var current = data[parent];
                    if (compare(item, current) >= 0) break;
                    data[pos] = current;
                    pos = parent;
                }

                data[pos] = item;
            },

            _down: function (pos) {
                var data = this.data;
                var compare = this.compare;
                var halfLength = this.length >> 1;
                var item = data[pos];

                while (pos < halfLength) {
                    var left = (pos << 1) + 1;
                    var right = left + 1;
                    var best = data[left];

                    if (right < this.length && compare(data[right], best) < 0) {
                        left = right;
                        best = data[right];
                    }
                    if (compare(best, item) >= 0) break;

                    data[pos] = best;
                    pos = left;
                }

                data[pos] = item;
            }
        };
        
        if (polygon.length === 3) {
            return getCentroidOfPolygon(polygon);
        }

        var precision = 0.01;
        polygon = [polygon];

        // find the bounding box of the outer ring
        var minX, minY, maxX, maxY;
        for (var i = 0; i < polygon[0].length; i++) {
            var p = polygon[0][i];
            if (!i || p.x < minX) minX = p.x;
            if (!i || p.y < minY) minY = p.y;
            if (!i || p.x > maxX) maxX = p.x;
            if (!i || p.y > maxY) maxY = p.y;
        }

        var width = maxX - minX;
        var height = maxY - minY;
        var cellSize = Math.min(width, height);
        var h = cellSize / 2;

        // a priority queue of cells in order of their "potential" (max distance to polygon)
        var cellQueue = new TinyQueue(null, compareMax);

        if (cellSize === 0) return [minX, minY];

        // cover polygon with initial cells
        for (var x = minX; x < maxX; x += cellSize) {
            for (var y = minY; y < maxY; y += cellSize) {
                cellQueue.push(new Cell(x + h, y + h, h, polygon));
            }
        }

        // take centroid as the first best guess
        var bestCell = getCentroidCell(polygon);

        // special case for rectangular polygons
        var bboxCell = new Cell(minX + width / 2, minY + height / 2, 0, polygon);
        if (bboxCell.d > bestCell.d) bestCell = bboxCell;

        while (cellQueue.length) {
            // pick the most promising cell from the queue
            var cell = cellQueue.pop();

            // update the best cell if we found a better one
            if (cell.d > bestCell.d) {
                bestCell = cell;
            }

            // do not drill down further if there's no chance of a better solution
            if (cell.max - bestCell.d <= precision) continue;

            // split the cell into four cells
            h = cell.h / 2;
            cellQueue.push(new Cell(cell.x - h, cell.y - h, h, polygon));
            cellQueue.push(new Cell(cell.x + h, cell.y - h, h, polygon));
            cellQueue.push(new Cell(cell.x - h, cell.y + h, h, polygon));
            cellQueue.push(new Cell(cell.x + h, cell.y + h, h, polygon));
        }

        return {x:bestCell.x, y:bestCell.y};
    };
